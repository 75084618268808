$sofiaSansRegular: "SofiaSans Regular";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-family: $sofiaSansRegular, sans-serif;
}

@font-face {
  font-family: "SofiaSans Light";
  font-display: swap;
  src: url("../fonts/SofiaSans/SofiaSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SofiaSans Regular";
  font-display: swap;
  src: url("../fonts/SofiaSans/SofiaSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SofiaSans Medium";
  font-display: swap;
  src: url("../fonts/SofiaSans/SofiaSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SofiaSans SemiBold";
  font-display: swap;
  src: url("../fonts/SofiaSans/SofiaSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SofiaSans Bold";
  font-display: swap;
  src: url("../fonts/SofiaSans/SofiaSans-Bold.ttf") format("truetype");
}

$sofiaSansLight: "SofiaSans Light";
$sofiaSansMedium: "SofiaSans Medium";
$sofiaSansSemiBold: "SofiaSans SemiBold";
$sofiaSansBold: "SofiaSans Bold";
$darkColor: #42424e;
$purpleColor: #4c64d0;
$darkPurpleColor: #606a82;
$lightPurpleColor: #6d6d8f;
$lightGrayColor: #f7f9fd;
$linkBlueColor: #0099f1;
$redColor: #ff4e77;
$greenColor: #08bc62;
$darkGreenColor: #3bc870;
$semiDarkWhiteColor: #dde6ed;
$semiLightGrayColor: #8f96a5;
$semiDarkGrayColor: #7c7c7c;
$smallFontSize: 15px;
$mediumFontSize: 18px;
$largeFontSize: 30px;
$extraLargeFontSize: 40px;
$ultraLargeFontSize: 50px;

.validate {
  border: 1px solid $redColor !important;
}

.wrapper {
  width: calc(100% - 30px);
  max-width: 2100px;
  margin: 0 auto;
}

.bottom-nav {
  display: none;
}

.pagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  li {
    cursor: pointer;
    // border: 1px solid #dde6ed;
    border-right: none;
    margin: 0 2px;
    a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #626262;
      font-size: $smallFontSize;
      border-radius: 2px;
      cursor: pointer;
    }
    a:hover {
      background-color: #f4f9fd;
    }
    &:last-of-type {
      border-right: 1px solid #dde6ed;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 1;
  }
  .active {
    cursor: default;
    a {
      color: white;
      background-color: $purpleColor;
      pointer-events: none;
      cursor: default;
    }
  }
}

.no-results {
  padding: 40px 0;
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 300px;
  }
  b {
    color: $darkColor;
    font-size: $largeFontSize;
    font-family: $sofiaSansSemiBold, sans-serif;
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-weight: normal;
  }
}

.full-screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  .MuiSkeleton-root {
    width: 100%;
    height: 50px;
    transform: scale(1);
    margin-bottom: 20px;
    &.big {
      flex: 1;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

hr {
  border: none;
  margin: 5px 0;
  display: block;
  height: 1px;
  background-color: #e6eef4;
}

.loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px;
    background-image: url("../images/app/loader.svg");
    background-color: rgba(255, 255, 255, 0.95);
  }
}

button.create-new {
  background-color: #fff !important;
  position: fixed;
  bottom: 66px;
  right: 15px;
  color: $purpleColor;
  font-size: $smallFontSize;
  text-transform: initial;
  font-family: $sofiaSansSemiBold, sans-serif;
  width: 160px;
  height: 43px;
  box-shadow: 0px 0px 38px rgba(45, 51, 101, 0.3);
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  padding: 0;
  padding-right: 30px;
  z-index: 9;
  > img {
    margin-right: 5px;
  }
  span.more {
    width: 30px;
    border-left: 1px solid #b8c7e5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 5px;
    img {
      pointer-events: none;
    }
    &:hover {
      background-color: rgb(250, 250, 250);
    }
  }
}

.with-inputs {
  label {
    font-family: $sofiaSansBold, sans-serif;
    color: $darkColor;
    font-size: $smallFontSize;
    padding-bottom: 5px;
    display: block;
  }
  input:not([type="checkbox"]),
  select {
    background: #f7f9ff;
    border: 1px solid #ededfa;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    color: $darkColor;
    font-size: $smallFontSize;
    -webkit-appearance: none;
    &::placeholder {
      color: $semiLightGrayColor;
    }
    &:focus {
      border-color: $purpleColor;
    }
  }
}

.success-message,
.error-message {
  width: 420px;
  min-height: 40px;
  padding: 10px;
  position: fixed;
  bottom: 15px;
  left: 15px;
  border-radius: 6px;
  font-size: $smallFontSize;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: 0.2s;
  z-index: 9;
  img {
    margin-right: 5px;
  }
  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

.success-message {
  background-color: $darkGreenColor;
}

.error-message {
  background-color: $redColor;
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 91;
  opacity: 0;
  pointer-events: none;
  .modal {
    padding: 20px;
    background: #fcfdff;
    box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.17);
    border-radius: 4px;
    transform: translateY(50px);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    overflow: hidden;
    .head {
      margin: -20px;
      margin-bottom: 0;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > button {
        width: 40px;
        height: 40px;
        min-width: initial;
      }
    }
    .body {
      > img.main {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      b {
        color: $darkColor;
        font-size: $extraLargeFontSize;
        font-family: $sofiaSansSemiBold, sans-serif;
        display: block;
        text-align: center;
        margin-bottom: 10px;
        font-weight: normal;
      }
      p {
        text-align: center;
        color: $semiLightGrayColor;
        font-size: $smallFontSize;
        line-height: 1.4;
        padding: 0 100px;
        margin-bottom: 40px;
      }
    }
    .footer {
      padding-top: 30px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        min-width: 190px;
        padding: 0 20px;
        height: 40px;
        background: $purpleColor;
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: initial;
        color: #fff;
        font-size: $smallFontSize;
        font-family: $sofiaSansSemiBold, sans-serif;
        line-height: 1;
        &.secondary {
          background: #edeff3;
          border: 1px solid #d3dae8;
          border-radius: 36px;
          color: $darkColor;
          margin-right: 10px;
        }
      }
      &.right {
        justify-content: flex-end;
      }
    }
    &.show {
      transform: translateY(0);
      pointer-events: all;
      opacity: 1;
    }
  }
  &.create-education {
    .modal {
      width: 770px;
      .head {
        margin: -20px;
        margin-bottom: 0;
        padding: 10px 20px;
        border-bottom: 1px solid #e2eaef;
        justify-content: space-between;
        ul.nav {
          display: flex;
          align-items: center;
          li {
            color: $semiLightGrayColor;
            font-size: $smallFontSize;
            cursor: pointer;
            padding: 0 5px;
            margin: 0 5px;
            &.active {
              color: $darkColor;
            }
          }
        }
        .dummy {
          width: 40px;
          height: 40px;
          min-width: initial;
        }
        .MuiTabs-root {
          margin-bottom: -13px;
          button {
            text-transform: initial;
            font-size: $smallFontSize;
            color: $semiLightGrayColor;
            font-family: $sofiaSansRegular, sans-serif;
            &.Mui-selected {
              color: $darkColor;
            }
          }
        }
        .css-1aquho2-MuiTabs-indicator {
          background-color: $purpleColor;
        }
      }
      .body {
        padding: 20px 0;
        .slide-1,
        .slide-2 {
          display: none;
          h6 {
            font-size: $smallFontSize;
            color: $lightPurpleColor;
            font-family: $sofiaSansSemiBold, sans-serif;
            text-align: center;
            margin-bottom: 5px;
            font-weight: normal;
          }
          &.show {
            display: block;
          }
        }
        .slide-1 {
          background-image: url("../images/admin/modals/education/bg.svg");
          background-position: top center;
          background-size: 100% auto;
          background-repeat: no-repeat;
          padding-top: 130px;
          .row {
            display: flex;
            align-items: flex-end;
            max-width: 550px;
            margin: 0 auto;
            margin-top: 20px;
            .col {
              flex: 1;
              border-bottom: 1px solid #aeb7c9;
              label {
                color: #aeb7c9;
                font-family: $sofiaSansSemiBold, sans-serif;
                font-size: $smallFontSize;
              }
              input[type="text"],
              select {
                border: none;
                width: 100%;
                height: 50px;
                font-size: $largeFontSize;
                font-family: $sofiaSansLight, sans-serif;
                color: $darkPurpleColor;
                &::placeholder {
                  color: $semiLightGrayColor;
                }
              }
              &.small {
                max-width: 100px;
                padding-right: 20px;
                .MuiInputBase-root {
                  width: 100%;
                  max-height: 50px;
                  .MuiButtonBase-root{
                    display: none;
                  }
                }
                .MuiInputBase-root {
                  max-width: 100%;
                  .MuiSelect-select {
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-right: 25px;
                    padding-left: 0;
                  }
                }
                .MuiOutlinedInput-notchedOutline {
                  border: none;
                }
              }
            }
          }
        }
        .slide-2 {
          button.image {
            background: #fff;
            border: 1px solid #e2eaef;
            border-radius: 4px;
            max-width: 410px;
            height: 230px;
            width: 100%;
            margin: 0 auto;
            padding: 15px;
            display: block;
            .upload {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              > img {
                display: block;
                margin: 0 auto;
                margin-bottom: 15px;
              }
              span {
                border: 1px solid $semiDarkWhiteColor;
                border-radius: 4px;
                padding: 0 10px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $semiLightGrayColor;
                text-transform: initial;
                font-size: $smallFontSize;
                img {
                  margin-right: 5px;
                }
              }
            }
            img.main {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              display: block;
            }
          }
        }
      }
    }
  }
  &.create-education-success {
    .modal {
      width: 770px;
      position: relative;
      .head {
        margin-bottom: -20px;
      }
      .body {
        h5 {
          text-align: center;
          font-weight: normal;
          font-size: $extraLargeFontSize;
          font-family: $sofiaSansLight, sans-serif;
          color: $darkColor;
          margin-bottom: 30px;
        }
      }
      .footer {
        button {
          padding: 0;
          a {
            padding: 0 20px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: inherit;
            font-size: inherit;
          }
        }
      }
      &::before {
        content: "";
        width: 150px;
        height: 200px;
        background-image: url("../images/admin/modals/education/success-left-decor.svg");
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &::after {
        content: "";
        width: 187px;
        height: 204px;
        background-image: url("../images/admin/modals/education/success-right-decor.svg");
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  &.create-topic {
    .body {
      button.image {
        background: #fff;
        border: 1px solid #e2eaef;
        border-radius: 4px;
        max-width: 410px;
        height: 230px;
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        display: block;
        .upload {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > img {
            display: block;
            margin: 0 auto;
            margin-bottom: 15px;
          }
          span {
            border: 1px solid $semiDarkWhiteColor;
            border-radius: 4px;
            padding: 0 10px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $semiLightGrayColor;
            text-transform: initial;
            font-size: $smallFontSize;
            img {
              margin-right: 5px;
            }
          }
        }
        img.main {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          display: block;
        }
      }
      .row {
        display: flex;
        align-items: flex-end;
        max-width: 550px;
        margin: 0 auto;
        margin-top: 20px;
        .col {
          flex: 1;
          label {
            color: #aeb7c9;
            font-family: $sofiaSansSemiBold, sans-serif;
            font-size: $smallFontSize;
          }
          input[type="text"],
          input[type="number"] {
            border: none;
            border-bottom: 1px solid #aeb7c9;
            width: 100%;
            height: 50px;
            font-size: $largeFontSize;
            font-family: $sofiaSansLight, sans-serif;
            color: $darkPurpleColor;
            &::placeholder {
              color: $semiLightGrayColor;
            }
          }
          input[type="number"] {
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button{
              -webkit-appearance: none;
              margin: 0;
            }
            &[type=number] {
              -moz-appearance: textfield;
            }
          }

          &.small {
            max-width: 50px;
            input {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  &.create-test {
    .modal {
      .body {
        .col {
          label {
            color: #aeb7c9;
            font-family: $sofiaSansSemiBold, sans-serif;
            font-size: $smallFontSize;
            display: block;
            text-align: center;
          }
          input[type="text"] {
            border: none;
            border-bottom: 1px solid #aeb7c9;
            width: 100%;
            height: 50px;
            font-size: $largeFontSize;
            font-family: $sofiaSansLight, sans-serif;
            color: $darkPurpleColor;
            text-align: center;
            max-width: 590px;
            margin: 0 auto;
            display: block;
            &::placeholder {
              color: $semiLightGrayColor;
            }
          }
        }
        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
          .MuiOutlinedInput-notchedOutline {
          border: none !important;
        }
        .MuiInputBase-root {
          background: #f7f9ff;
          border: 1px solid #ededfa;
          border-radius: 4px;
          width: 100%;
          max-width: 590px;
          display: block;
          margin: 0 auto;
          margin-top: 15px;
          .MuiSelect-select {
            padding: 9.5px 14px;
            padding-right: 32px;
            font-family: $sofiaSansMedium, sans-serif;
            color: $darkColor;
            font-size: $mediumFontSize;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &.timer {
    .modal {
      max-width: 250px;
      .body {
        b {
          font-size: $mediumFontSize;
        }
        input[type="number"] {
          background: #e3e6ee;
          border: 1px solid #bdccde;
          border-radius: 4px;
          color: #8f96a5;
          font-size: $smallFontSize;
          font-family: "SofiaSans Medium", sans-serif;
          height: 35px;
          padding-top: 0;
          padding-bottom: 0;
          background-color: #fff;
          padding: 0 5px;
          max-width: 50px;
        }
        .dots {
          padding: 0 5px;
          color: $darkColor;
          font-size: $smallFontSize;
        }
      }
      .footer {
        padding-bottom: 0;
        button {
          flex: 1;
          min-width: initial;
        }
      }
    }
  }
  &.grade {
    .body {
      b {
        margin-bottom: 30px;
        font-size: $largeFontSize;
      }
      .row {
        font-size: $smallFontSize;
        color: $darkColor;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          font-family: $sofiaSansSemiBold, sans-serif;
        }
        input {
          background: #fff;
          border: 1px solid #bdccde;
          border-radius: 4px;
          color: #8f96a5;
          font-size: $smallFontSize;
          font-family: "SofiaSans Medium", sans-serif;
          height: 25px;
          width: 50px;
          padding: 0 5px;
          margin: 0 5px;
        }
      }
      .bottom {
        background-color: #dee3f3;
        padding: 10px 20px;
        color: $darkPurpleColor;
        font-size: $smallFontSize;
        text-align: right;
        margin-right: -20px;
        margin-left: -20px;
        width: calc(100% + 40px);
        span {
          font-family: $sofiaSansSemiBold, sans-serif;
        }
      }
    }
  }
  &.question,
  &.delete,
  &.change-password {
    .modal {
      max-width: 505px;
      .body {
        b {
          font-size: $largeFontSize;
        }
        p {
          padding: 0;
          margin-bottom: 20px;
        }
      }
      .footer {
        button {
          min-width: 100px;
        }
      }
    }
  }
  &.delete {
    .modal {
      .footer {
        button:not(.secondary) {
          background-color: $redColor;
        }
      }
    }
  }
  &.change-password,
  &.add-edit-student {
    .modal {
      .body {
        .col {
          margin-bottom: 15px;
        }
      }
    }
  }
  &.add-edit-student {
    .modal {
      max-width: 730px;
      .body {
        .fields {
          h5 {
            color: $purpleColor;
            font-size: $mediumFontSize;
            font-family: $sofiaSansSemiBold, sans-serif;
            margin-bottom: 10px;
            font-weight: normal;
          }
          .row {
            display: flex;
            margin-bottom: 20px;
            .col {
              flex: 1;
              margin-right: 10px;
              select {
                width: 70px;
              }
              &.small {
                max-width: fit-content;
              }
              &:last-of-type {
                margin-right: 0;
              }
              span.message {
                font-size: 11px;
                color: $redColor;
                line-height: 1;
                display: block;
                padding-top: 5px;
              }
            }
          }
        }
      }
      .footer {
        button {
          min-width: 100px;
        }
      }
    }
  }
  &.scrollable {
    align-items: flex-start;
    overflow: auto;
    .modal {
      margin: 10px 0;
    }
  }
  &.gallery {
    span.close {
      display: block;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      background-image: url("../images/students/topic/icons/close.svg");
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
    }
    span.prev,
    span.next {
      display: block;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      position: absolute;
      top: calc(50% - 40px / 2);
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      background-size: 15px auto;
      border-radius: 4px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    span.prev {
      background-image: url("../images/students/topic/icons/prev-arrow.svg");
      left: 15px;
    }

    span.next {
      background-image: url("../images/students/topic/icons/next-arrow.svg");
      right: 15px;
    }
    .modal {
      height: calc(100vh - 80px);
      width: calc(100vw - 150px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000;
      img.main {
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
        pointer-events: none;
      }
    }
  }
  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

header {
  border-bottom: 1px solid #e9eef8;
  background-color: #fff;
  .wrapper {
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a.brand {
      // background-color: #eee;
      display: flex;
      align-items: center;
      img{
        width: 60px;
        height: 60px;
        margin-right: 10px;
        flex-shrink: 0;
       }
       b{
        display: block;
        font-size: $smallFontSize;
        color: $darkColor;
        max-width: 140px;
        font-family: $sofiaSansSemiBold, sans-serif;
        line-height: 1.2;
       }
    }
    nav {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin: 0 15px;
        span {
          color: $purpleColor;
          font-size: $mediumFontSize;
          font-family: $sofiaSansBold;
          display: none;
        }
        &.active {
          img {
            margin-right: 5px;
          }
          span {
            display: block;
          }
        }
      }
    }
    .user {
      display: flex;
      align-items: center;
      > span {
        color: $darkColor;
        font-size: $smallFontSize;
        font-family: $sofiaSansBold;
        margin-right: 10px;
        line-height: 1.2;
      }
      > a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      button.logout {
        text-transform: initial;
        color: $darkColor;
        font-size: $smallFontSize;
        font-family: $sofiaSansMedium, sans-serif;
        margin-left: 5px;
      }
    }
  }
}

footer {
  background-color: #62666d;
  .wrapper {
    height: 50px;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: #fff;
      font-size: 13px;
    }
    .software-partner {
      a {
        width: 60px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}

section {
  //височината на екрана(100vh) - височината на хедъра (83px) + височината на боръдара под хедъра (1px)
  min-height: calc(100vh - (84px + 50px));
}

#initial-screen {
  display: flex;
  align-items: center;
  background-color: #e9eef8;
  min-height: 100vh;
  .wrapper {
    img {
    }
    picture {
      width: 200px;
      height: 200px;
      display: block;
      margin: 0 auto;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
        animation: heartbeat 1.5s ease-in-out infinite both;
      }
      &::before {
        content: "";
        width: calc(100% + 40px);
        height: calc(100% + 40px);
        background-image: url("../images/app/loader-init.svg");
        position: absolute;
        left: -20px;
        top: -20px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    @-webkit-keyframes heartbeat {
      from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }
    @keyframes heartbeat {
      from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }
  }
}

#student-topics,
#student-tests {
  background-color: #e9eef8;
  .top-skeleton {
    padding: 20px;
    margin-bottom: 10px;
    .MuiSkeleton-root {
      width: 100%;
      height: 200px;
      transform: scale(1);
    }
  }
  @media screen and (max-width: 760px) {
    .top-skeleton {
      .MuiSkeleton-root {
        height: 65px;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -15px;
        width: calc(100% + 30px);
      }
    }
  }
  .wrapper {
    padding: 20px 0;
    padding-bottom: 5px;
    .top {
      border-radius: 10px;
      background-image: url("../images/students/topics/top-bg.jpg");
      background-size: cover;
      background-position: center;
      padding: 15px;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        padding-right: 40px;
        h1 {
          font-size: $extraLargeFontSize;
          color: $darkColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-bottom: 10px;
          font-weight: normal;
          button {
            width: 40px;
            height: 40px;
            min-width: initial;
            margin-right: 5px;
          }
        }
        p {
          font-style: normal;
          $sofiaSansBold: "SofiaSans Bold";
          font-size: $mediumFontSize;
          color: $darkColor;
          padding-left: 50px;
        }
        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
          .MuiOutlinedInput-notchedOutline {
          border: none !important;
        }
        .MuiInputBase-root {
          background-color: rgba(227, 197, 148, 0.6);
          .MuiSelect-select {
            padding: 9.5px 14px;
            padding-right: 32px;
            font-family: $sofiaSansMedium, sans-serif;
            color: $darkColor;
            font-size: $mediumFontSize;
            display: flex;
            align-items: center;
          }
        }
        .progress {
          max-width: 100%;
          min-width: 20px;
          height: 11px;
          background: linear-gradient(
            269.98deg,
            #c0a89b -1.08%,
            #ffe7c2 100.87%
          );
          border-radius: 10px;
          position: relative;
          margin-top: 40px;
          span {
            position: absolute;
            left: calc(100% + 5px);
            font-size: $mediumFontSize;
            color: $darkColor;
            top: 50%;
            transform: translateY(-50%);
            font-family: $sofiaSansSemiBold, sans-serif;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        width: fit-content;
        flex-shrink: 0;
        svg[viewBox] {
          width: 100px !important;
          height: 100px !important;
        }
        .results {
          margin-left: 20px;
          b {
            font-family: $sofiaSansSemiBold, sans-serif;
            font-size: 55px;
            color: $darkColor;
            display: block;
            margin-bottom: 5px;
            font-weight: normal;
          }
          .indicator {
            display: flex;
            align-items: center;
            color: $darkColor;
            font-size: $smallFontSize;
            font-family: $sofiaSansMedium, sans-serif;
            span {
              width: 17px;
              height: 17px;
              border-radius: 4px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .bottom {
      > ul {
        margin-top: -35px;
        padding: 15px;
        padding-top: 0;
        display: flex;
        flex-wrap: wrap;
        // gap: 15px;
        margin-right: -15px;
        width: calc(100% + 15px);
        .MuiSkeleton-root {
          min-height: 250px;
          width: calc(100% / 5 - 15px);
          margin-right: 15px;
          margin-bottom: 15px;
          transform: scale(1);
        }
        > li {
          background-color: #fff;
          border-radius: 3px;
          padding: 15px;
          width: calc(100% / 6 - 15px);
          cursor: default;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          position: relative;
          margin-right: 15px;
          margin-bottom: 15px;
          &:hover {
            box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.48);
          }
        }
        @media screen and (max-width: 1700px) {
          .MuiSkeleton-root {
            width: calc(100% / 5 - 15px);
          }
          > li {
            width: calc(100% / 5 - 15px);
          }
        }
        @media screen and (max-width: 1500px) {
          .MuiSkeleton-root {
            width: calc(100% / 4 - 15px);
          }
          > li {
            width: calc(100% / 4 - 15px);
          }
        }
        @media screen and (max-width: 1300px) {
          .MuiSkeleton-root {
            width: calc(100% / 3 - 15px);
          }
          > li {
            width: calc(100% / 3 - 15px);
          }
        }
        @media screen and (max-width: 1024px) {
          .MuiSkeleton-root {
            width: calc(100% / 2 - 15px);
          }
          > li {
            width: calc(100% / 2 - 15px);
          }
        }
        @media screen and (max-width: 760px) {
          .MuiSkeleton-root {
            width: 100%;
            min-height: 110px;
            margin-right: 0;
          }
          > li {
            width: 100%;
          }
        }
      }
    }
  }
}

#student-topics {
  .wrapper {
    .bottom {
      > ul {
        > li {
          i.number {
            background: #fff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            width: 46px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #4e66d1;
            font-size: $largeFontSize;
            font-style: normal;
            font-family: $sofiaSansBold;
            position: absolute;
            top: 25px;
            left: 25px;
          }
          img.main {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            object-position: center;
            border-radius: 3px;
            display: block;
            margin-bottom: 10px;
            flex-shrink: 0;
          }
          span.included {
            display: block;
            text-align: center;
            font-size: $smallFontSize;
            color: $lightPurpleColor;
            font-family: $sofiaSansSemiBold, sans-serif;
            margin-bottom: 10px;
          }
          p {
            text-align: center;
            color: $darkColor;
            line-height: 1.4;
            font-family: $sofiaSansSemiBold, sans-serif;
            margin-bottom: 40px;
          }
          .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;
            flex-shrink: 0;
            width: 100%;
            span {
              display: flex;
              align-items: center;
              color: $semiLightGrayColor;
              font-size: $smallFontSize;
              i {
                background-color: #fff;
                margin-right: 5px;
                padding: 5px;
                border-radius: 50%;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
              }
            }
          }
          .progress {
            display: flex;
            align-items: center;
            margin-top: auto;
            width: 100%;
            .bar {
              flex: 1;
              background: $semiDarkWhiteColor;
              border-radius: 10px;
              height: 9px;
              position: relative;
              overflow: hidden;
              .indicator {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                transition: 0.2s;
                min-width: 2px;
                background-color: #556cd7;
                border-radius: inherit;
              }
            }
            b {
              width: 55px;
              flex-shrink: 0;
              display: block;
              text-align: center;
            }
          }
          .hover {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
            transform: translateY(-100%);
            background-color: rgba(255, 255, 255, 0.95);
            transition: 0.2s;
            padding: 15px;
            display: flex;
            flex-direction: column;
            .status {
              padding: 7px 10px;
              background: #ffce31;
              border-radius: 32px;
              font-size: $smallFontSize;
              font-family: $sofiaSansSemiBold;
              color: #fff;
              display: flex;
              align-items: center;
              width: fit-content;
              img {
                margin-right: 5px;
              }
              &.not-started {
                background: #b6b6b6;
              }
              &.started {
                background: #ffce31;
              }
              &.finished {
                background: $greenColor;
              }
            }
            ul {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              li {
                border-radius: 10px;
                width: 100%;
                height: 98px;
                margin: 10px 0;
                overflow: hidden;
                a {
                  padding: 0 20px;
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  font-size: $largeFontSize;
                  font-family: $sofiaSansBold;
                  span {
                    width: 53px;
                    height: 53px;
                    background: #fff;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    flex-shrink: 0;
                    img {
                      transition: 0.2s;
                    }
                  }
                }
                &.topic {
                  a {
                    background: linear-gradient(
                      180deg,
                      #7d93f5 -14.61%,
                      $purpleColor 100%
                    );
                    color: #fff;
                  }
                }
                &.test {
                  a {
                    background: #f9e0df;
                    color: $darkColor;
                  }
                }
                &:hover {
                  a {
                    span {
                      img {
                        transform: scale(1.05);
                      }
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .hover {
              opacity: 1;
              bottom: 0;
              transform: translateY(0);
              pointer-events: all;
            }
          }
        }
      }
    }
  }
}

#student-tests {
  .wrapper {
    .bottom {
      > ul {
        > li {
          padding: 0;
          a {
            padding: 15px;
            width: 100%;
            height: 100%;
            display: inherit;
            flex-direction: inherit;
            justify-content: inherit;
            align-items: inherit;
            b {
              font-size: $largeFontSize;
              text-align: center;
              font-family: $sofiaSansSemiBold, sans-serif;
              display: block;
              color: $darkColor;
              font-weight: normal;
            }
            .test-icon {
              height: 120px;
              width: 120px;
              background-image: url("../images/students/tests/icons/test.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              position: relative;
              margin-bottom: 10px;
              &::after {
                content: "";
                width: 40px;
                height: 40px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
              &.good::after {
                background-image: url("../images/students/tests/icons/good.svg");
              }
              &.bad::after {
                background-image: url("../images/students/tests/icons/bad.svg");
              }
            }
            p {
              color: $darkColor;
              font-size: $mediumFontSize;
              $sofiaSansSemiBold: "SofiaSans Bold";
              text-align: center;
              line-height: 1.2;
              margin-bottom: 10px;
            }
            ul.results {
              display: flex;
              align-items: center;
              justify-content: center;
              li {
                margin: 0 5px;
                display: flex;
                align-items: center;
                color: $semiLightGrayColor;
                font-size: $smallFontSize;
                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  background-color: #fff;
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                  border-radius: 50%;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

#test {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $lightGrayColor;
  display: flex;
  flex-direction: column;
  // gap: 15px;
  .header {
    border-bottom: 1px solid #bdccde;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    margin-bottom: 15px;
    .wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button.back {
          min-width: initial;
          margin-right: 10px;
          padding: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .heading {
          flex-shrink: 0;
          max-width: 300px;
          padding: 10px 15px;
          box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
          border-radius: 4px;
          background-color: $darkColor;
          color: #fff;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-right: 10px;
          font-size: $smallFontSize;
        }
        p {
          color: $darkColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          font-size: $smallFontSize;
          line-height: 1;
          max-width: 300px;
        }
      }
      .mid {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 10px;
          margin-top: -7px;
        }
        time {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 105px;
          font-size: $extraLargeFontSize;
          color: #37447f;
          font-family: $sofiaSansSemiBold, sans-serif;
          span {
            font-family: inherit;
          }
        }
        .of-total {
          margin-top: auto;
          margin-bottom: 5px;
          margin-left: 5px;
          color: #37447f;
          font-family: $sofiaSansSemiBold, sans-serif;
          font-size: $smallFontSize;
        }
        &.timeout {
          img {
            -webkit-animation: vibrate-1 0.3s linear infinite both;
            animation: vibrate-1 0.3s linear infinite both;
          }
          @-webkit-keyframes vibrate-1 {
            0% {
              -webkit-transform: translate(0);
              transform: translate(0);
            }
            20% {
              -webkit-transform: translate(-1px, 1px);
              transform: translate(-1px, 1px);
            }
            40% {
              -webkit-transform: translate(-1px, -1px);
              transform: translate(-1px, -1px);
            }
            60% {
              -webkit-transform: translate(1px, 1px);
              transform: translate(1px, 1px);
            }
            80% {
              -webkit-transform: translate(1px, -1px);
              transform: translate(1px, -1px);
            }
            100% {
              -webkit-transform: translate(0);
              transform: translate(0);
            }
          }
          @keyframes vibrate-1 {
            0% {
              -webkit-transform: translate(0);
              transform: translate(0);
            }
            20% {
              -webkit-transform: translate(-1px, 1px);
              transform: translate(-1px, 1px);
            }
            40% {
              -webkit-transform: translate(-1px, -1px);
              transform: translate(-1px, -1px);
            }
            60% {
              -webkit-transform: translate(1px, 1px);
              transform: translate(1px, 1px);
            }
            80% {
              -webkit-transform: translate(1px, -1px);
              transform: translate(1px, -1px);
            }
            100% {
              -webkit-transform: translate(0);
              transform: translate(0);
            }
          }
          time {
            animation: toggle-color 1s infinite both;
          }
          @keyframes toggle-color {
            0% {
              color: #37447f;
            }
            50% {
              color: #e63131;
            }
            100% {
              color: #37447f;
            }
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .answered {
          color: $darkColor;
          font-family: $sofiaSansBold;
          font-size: $smallFontSize;
          b {
            display: block;
            font-size: 25px;
            font-family: inherit;
            color: inherit;
          }
        }
        button {
          background: #7e7e7e;
          border-radius: 31px;
          width: 129px;
          height: 43px;
          color: #fff;
          font-family: $sofiaSansSemiBold;
          font-size: $smallFontSize;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          padding-top: 1px;
          margin-left: 15px;
          &.ready {
            background: $darkGreenColor;
          }
        }
      }
    }
    .timeline {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      span {
        height: 100%;
        min-width: 5px;
        max-width: 100%;
        background-color: #bdccde;
        display: block;
        width: 0;
        transition: 1s linear;
      }
    }
  }
  .main {
    flex: 1;
    display: flex;
    margin-bottom: 15px;
    .wrapper {
      height: 100%;
      display: flex;
      // gap: 15px;
      button.changer {
        background-position: center;
        background-size: 48px 48px;
        background-repeat: no-repeat;
        height: 100%;
        width: 125px;
        background-color: #fff;
        border: 1px solid $semiDarkWhiteColor;
        box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
        flex-shrink: 0;
        transition: 0.5s;
        opacity: 1;
        flex-shrink: 0;
        &.prev {
          background-image: url("../images/students/test/icons/prev.svg");
          border-radius: 50px 4px 4px 50px;
        }
        &.next {
          background-image: url("../images/students/test/icons/next.svg");
          border-radius: 4px 50px 50px 4px;
        }
      }
      ul.container {
        display: flex;
        flex: 1;
        margin: 0 15px;
        > li {
          flex: 1;
          display: none;
          flex-direction: column;
          // gap: 15px;
          .question {
            height: fit-content;
            padding: 20px 10px;
            padding-top: 30px;
            background: #fff;
            border: 1px solid $semiDarkWhiteColor;
            box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
            border-radius: 4px;
            position: relative;
            margin-bottom: 15px;
            ul {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 10px;
              left: 50%;
              transform: translateX(-50%);
              li {
                margin: 0 10px;
                font-size: $smallFontSize;
                color: $darkColor;
              }
            }
            h1 {
              text-align: center;
              font-size: $largeFontSize;
              color: $darkColor;
              font-family: $sofiaSansBold;
              font-weight: normal;
            }
          }
          .answers {
            flex: 1;
            background: #fff;
            border: 1px solid $semiDarkWhiteColor;
            box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
            border-radius: 4px;
            display: flex;
            padding: 15px;
            .table {
              // border: none;
              min-width: 300px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-right: 20px;
              table {
                // border: 1px solid $darkColor;
                th,
                td {
                  border-right: 1px solid $darkColor;
                  border-bottom: 1px solid $darkColor;
                  font-size: $mediumFontSize;
                  color: $darkColor;
                  font-weight: normal;
                  width: 60px;
                  height: 60px;
                  text-align: center;
                  input {
                    padding: 5px 0;
                    border: none;
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                  }
                  .var {
                    display: flex;
                    align-items: flex-end;
                    text-align: center;
                    justify-content: center;
                    position: relative;
                    span {
                      font-size: 14px;
                      margin-bottom: -3px;
                      display: block;
                      padding-left: 1px;
                    }
                    i{
                      font-size: 11px;
                      font-style: normal;
                      position: absolute;
                      top: calc(100% + 5px);
                      left: 50%;
                      transform: translateX(-50%);
                    }
                  }
                  &:last-of-type {
                    border-right: none;
                  }
                }
                th {
                  background-color: $lightGrayColor;
                  &.empty{
                    i{
                      font-size: 11px;
                      font-style: normal;
                      line-height: 1;
                      // display: block;
                    }
                    hr{
                      background-color: $darkColor;
                    }
                  }
                }
                tbody {
                  tr {
                    td {
                      &:first-of-type {
                        background-color: $lightGrayColor;
                      }
                    }
                    &:last-of-type {
                      td {
                        border-bottom: none;
                        &:last-of-type {
                          background-color: $lightGrayColor;
                        }
                      }
                    }
                  }
                }
              }
            }
            ul.type-list {
              flex: 1;
              width: 100%;
              display: flex;
              flex-direction: column;
              // gap: 10px;
              li {
                background: #f2f5ff;
                border: 1px solid $semiDarkWhiteColor;
                border-radius: 4px;
                padding: 10px 40px;
                flex: 1;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                span.code {
                  position: absolute;
                  width: 20px;
                  top: 50%;
                  left: 15px;
                  transform: translateY(-50%);
                  color: $darkColor;
                  font-size: $mediumFontSize;
                }
                p {
                  font-size: $mediumFontSize;
                  color: $darkColor;
                  position: relative;
                  z-index: 2;
                  width: 100%;
                }
                &:hover {
                  background: #e9ecf5;
                }
                &::after {
                  content: "";
                  width: 150px;
                  height: 100%;
                  right: 0;
                  top: 0;
                  position: absolute;
                  z-index: 1;
                  background-position: calc(100% - 15px), center;
                  background-size: 15px, 100%;
                  background-repeat: no-repeat;
                }
                &.correct::after {
                  background-image: url("../images/students/test/icons/check.svg"),
                    linear-gradient(
                      270deg,
                      $darkGreenColor 0%,
                      rgba(242, 245, 255, 0) 100%
                    );
                }
                &.incorrect::after {
                  background-image: url("../images/students/test/icons/cross.svg"),
                    linear-gradient(
                      270deg,
                      $redColor 0%,
                      rgba(242, 245, 255, 0) 100%
                    );
                }
                &.answered {
                  background-color: #9ea3bd;
                  span.code {
                    color: #fff;
                  }
                  p {
                    color: #fff;
                  }
                }
              }
            }
          }
          &.text-text {
            .answers {
              ul.type-list {
                li {
                  margin-bottom: 10px;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          &.image-text {
            .answers {
              display: flex;
              // gap: 50px;
              picture,
              .table {
                display: block;
                flex: 1 1 0%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 50px;
              }
              ul.type-list {
                li {
                  margin-bottom: 10px;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          &.text-image {
            .answers {
              ul.type-list {
                flex-direction: row;
                li {
                  background-position: center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: relative;
                  background-color: #f2f5ff !important;
                  margin-right: 10px;
                  &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    box-shadow: inset 0px 0px 35px 0px #696969;
                    opacity: 0;
                    transition: 0.2s;
                  }
                  &.answered::after {
                    opacity: 1;
                  }
                  &:hover {
                    box-shadow: inset 0px 0px 35px 0px #c3c3c3;
                    opacity: 1;
                  }
                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
            }
          }
          &.active {
            display: flex;
          }
        }
      }
    }
  }
  .footer {
    flex-shrink: 0;
    padding-bottom: 15px;
    .wrapper {
      padding: 15px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
      ul {
        display: flex;
        // gap: 5px;
        flex-wrap: wrap;
        li {
          background-color: #c8cff1;
          border-radius: 9px;
          padding: 5px;
          width: 50px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          border: 1px solid transparent;
          margin-right: 5px;
          b {
            font-weight: normal;
            font-size: 13px;
            color: $purpleColor;
            font-family: $sofiaSansSemiBold, sans-serif;
            display: block;
            text-align: center;
            margin-bottom: 5px;
            flex-shrink: 0;
          }
          .answers {
            display: flex;
            // gap: 3px;
            // flex: 1;
            height: 21px;
            span {
              flex: 1;
              background-color: $purpleColor;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 100%;
                max-height: 100%;
                display: block;
                object-fit: contain;
              }
            }
          }
          &.text-text {
            .answers {
              flex-direction: column;
              span {
                margin-bottom: 3px;
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
          &.text-image {
            .answers {
              span {
                padding: 1px;
                margin-right: 3px;
                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
          }
          &.image-text {
            .answers {
              span {
                padding: 2px;
              }
            }
          }
          &.active {
            background-color: $purpleColor;
            border: 1px solid #bdccde;
            b {
              color: #fff;
            }
            .answers {
              span {
                background-color: #9dacec;
              }
            }
          }
          &.correct {
            background-color: $darkGreenColor;
            &.active {
              background-color: #259e53;
            }
          }
          &.incorrect {
            background-color: $redColor;
            &.active {
              background-color: #e8345e;
            }
          }
          &.correct,
          &.incorrect {
            b {
              color: #fff;
            }
            .answers {
              span {
                background-color: #fff;
              }
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}

#topic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $lightGrayColor;
  display: flex;
  flex-direction: column;
  // gap: 15px;
  .header {
    border-bottom: 1px solid #bdccde;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    .wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button.back {
          min-width: initial;
          margin-right: 10px;
          padding: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .heading {
          flex-shrink: 0;
          max-width: 300px;
          padding: 10px 15px;
          box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
          border-radius: 4px;
          background-color: $darkColor;
          color: #fff;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-right: 10px;
          font-size: 35px;
        }
        p {
          color: $darkColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          font-size: 24px;
          line-height: 1;
        }
      }
    }
  }
 .navigation-holder{
  position: relative;
  margin: 15px 20px;
  .navigation {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-shrink: 0;
    padding: 10px 20px;
    ul {
      display: flex;
      align-items: center;
      width: fit-content;
      li {
        padding: 10px 15px;
        margin: 0 2.5px;
        cursor: pointer;
        font-size: $largeFontSize;
        position: relative;
        &:hover {
          background-color: rgb(246, 246, 246);
        }
        span {
          color: $semiLightGrayColor;
          position: relative;
          z-index: 2;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          background: $purpleColor;
          border-radius: 41px;
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          transition: 0.2s;
          transform: translate(-50%, -50%);
          opacity: 0;
          pointer-events: none;
        }
        &.active {
          span {
            color: #fff;
          }
          &::before {
            width: 100%;
            height: calc(100% + 15px);
            opacity: 1;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &.grab {
      cursor: grab;
    }
  }
  &::before {
    content: "";
    width: 100%;
    background: #ffffff;
    height: calc(100% - 20px);
    box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
 }
  .main {
    flex: 1;
    display: flex;
    padding-bottom: 15px;
    .wrapper {
      height: 100%;
      display: flex;
      // gap: 15px;
      button.changer {
        background-position: center;
        background-size: 48px 48px;
        background-repeat: no-repeat;
        height: 100%;
        width: 125px;
        background-color: #fff;
        border: 1px solid $semiDarkWhiteColor;
        box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
        flex-shrink: 0;
        transition: 0.5s;
        opacity: 1;
        flex-shrink: 0;
        &.prev {
          background-image: url("../images/students/test/icons/prev.svg");
          border-radius: 50px 4px 4px 50px;
        }
        &.next {
          background-image: url("../images/students/test/icons/next.svg");
          border-radius: 4px 50px 50px 4px;
        }
      }
      ul.container {
        display: flex;
        flex: 1;
        background: #fff;
        border: 1px solid $semiDarkWhiteColor;
        box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
        border-radius: 4px;
        background-image: url("../images/students/topic/bg.svg");
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 0 15px;
        > li {
          flex: 1;
          display: none;
          flex-direction: column;
          // gap: 15px;
          // height: fit-content;
          padding: 20px 10px;
          position: relative;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h1.child {
            text-align: center;
            font-family: $sofiaSansSemiBold, sans-serif;
            color: $darkColor;
            font-size: $extraLargeFontSize;
            margin-bottom: 10px;
            font-weight: normal;
          }
          p.child {
            color: $semiLightGrayColor;
            font-size: $mediumFontSize;
            text-align: center;
            line-height: 1.4;
          }
          .last-slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              margin-bottom: 40px;
            }
            button {
              border-radius: 36px;
              padding: 0;
              margin-right: 15px;
              overflow: hidden;
              a {
                font-size: $smallFontSize;
                text-align: center;
                text-transform: initial;
                width: 217px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                $sofiaSansBold: "SofiaSans Bold";
                background-color: #7086ed;
                color: #fff;
              }
            }
          }
          &.heading-images-text,
          &.images-text {
            h1 {
              margin-bottom: 20px;
            }
            ul.gallery {
              display: flex;
              justify-content: center;
              margin-bottom: 10px;
              width: calc(100% + 5px);
              margin-right: -5px;
              // gap: 5px;
              li {
                // flex: 1;
                aspect-ratio: 1/1;
                background-color: #fff;
                min-width: 40px;
                min-height: 40px;
                max-height: 300px;
                width: fit-content;
                border: 1px solid $semiDarkWhiteColor;
                box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.05);
                border-radius: 2px;
                overflow: hidden;
                padding: 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  display: block;
                  object-fit: contain;
                  cursor: pointer;
                }
              }
            }
          }
          &.heading-image-text {
            flex-direction: row;
            img {
              display: block;
              max-width: 50%;
              background-color: #fff;
              min-width: 40px;
              min-height: 40px;
              max-height: 300px;
              border: 1px solid $semiDarkWhiteColor;
              box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.05);
              flex-shrink: 0;
              margin-right: 15px;
              cursor: pointer;
            }
            .holder {
              display: flex;
              flex-direction: column;
              justify-content: center;
              // gap: 15px;
              h1 {
                text-align: left;
              }
              p {
                text-align: left;
              }
            }
          }
          &.active {
            display: flex;
          }
          .timeline {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            span {
              height: 100%;
              min-width: 5px;
              max-width: 100%;
              background-color: $darkGreenColor;
              display: block;
              width: 0;
              transition: 1s linear;
            }
          }
        }
      }
    }
  }
}

#result {
  background-image: url("../images/students/result/bg.svg");
  background-position: center;
  background-size: cover;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow: auto;
  display: flex;
  // min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 150px;
    flex: 1;
    position: relative;
    z-index: 2;
    .header {
      a.brand {
        // background-color: #eee;
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 3px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .body {
      margin: 80px 0;
      position: relative;
      margin-top: auto;
      .holder {
        width: fit-content;
        margin-bottom: 60px;
        h6 {
          color: #fff;
          $sofiaSansSemiBold: "SofiaSans SemiBold", sans-serif;
          font-size: 20px;
          font-weight: normal;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: -30px;
        }
        h2 {
          $sofiaSansSemiBold: "SofiaSans Medium", sans-serif;
          font-weight: normal;
          font-size: 80px;
          text-transform: uppercase;
          color: #fff;
          line-height: 1;
          position: relative;
          span {
            font-size: 120px;
            $sofiaSansSemiBold: "SofiaSans Bold", sans-serif;
          }
          &::after {
            content: "";
            width: calc(100% + 50px);
            height: 50px;
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
            background-image: url("../images/students/result/grade-shape.svg");
            position: absolute;
            top: calc(100% - 10px);
            left: 0;
          }
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          display: flex;
          align-items: center;
          margin-right: 80px;
          img {
            margin-right: 10px;
            flex-shrink: 0;
          }
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            b,
            span {
              color: #fff;
              $sofiaSansBold: "SofiaSans Bold", sans-serif;
            }
            b {
              font-size: 47px;
            }
            span {
              font-size: $mediumFontSize;
              text-transform: uppercase;
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      &::after {
        content: "";
        width: 500px;
        height: 365px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.good::after {
        background-image: url("../images/students/result/good.svg");
      }
      &.bad::after {
        background-image: url("../images/students/result/bad.svg");
      }
    }
    .footer {
      margin-bottom: auto;
      button {
        border-radius: 36px;
        padding: 0;
        margin-right: 15px;
        a {
          font-size: $smallFontSize;
          text-align: center;
          text-transform: initial;
          width: 217px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          $sofiaSansBold: "SofiaSans Bold";
        }
        &.view-test {
          background: #cbd4ff;
          a {
            color: #3d4b89;
          }
        }
        &.to-list {
          background: #7086ed;
          img {
            margin-right: 5px;
            -webkit-animation: jello-horizontal 0.9s infinite both;
            animation: jello-horizontal 0.9s infinite both;
          }
          @-webkit-keyframes jello-horizontal {
            0% {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
            30% {
              -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
            }
            40% {
              -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
            }
            50% {
              -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
            }
            65% {
              -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
            }
            75% {
              -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
            }
            100% {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
          }
          @keyframes jello-horizontal {
            0% {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
            30% {
              -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
            }
            40% {
              -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
            }
            50% {
              -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
            }
            65% {
              -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
            }
            75% {
              -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
            }
            100% {
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
          }

          a {
            color: #fff;
          }
        }
      }
    }
  }
  &::before {
    content: "";
    width: 304px;
    height: 168px;
    background-image: url("../images/students/result/top-left-shape.svg");
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &::after {
    content: "";
    width: 358px;
    height: 255px;
    background-image: url("../images/students/result/bottom-right-shape.svg");
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
}

.MuiPopover-paper {
  .MuiButtonBase-root {
    font-size: $smallFontSize;
    color: $darkColor;
    font-family: $sofiaSansRegular, sans-serif;
  }
}
.education-menu {
  .MuiButtonBase-root {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    img {
      width: 25px;
      object-fit: scale-down;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
}
.education-content-indicator {
  background: #ffffff;
  box-shadow: 0px 0px 31px rgba(69, 80, 120, 0.62);
  border-radius: 10px;
  width: 92px;
  height: 92px;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
  &::before {
    content: "";
    width: 85px;
    height: 85px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/teachers/education/icons/book.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  &::after {
    content: "";
    width: 85px;
    height: 85px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/teachers/education/icons/list.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  &.topics-only::before {
    opacity: 1;
  }
  &.tests-only::after {
    opacity: 1;
  }

  &.both {
    &::before {
      opacity: 1;
      width: 60px;
      height: 60px;
      transform: none;
      top: 36px;
      left: 6px;
    }
    &::after {
      opacity: 1;
      width: 45px;
      height: 45px;
      top: 32px;
      left: 62px;
    }
  }
}

span.education-menu {
  width: 32px;
  height: 32px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("../images/teachers/education/icons/dots.svg");
  background-size: 30px auto;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  &:hover {
    background-color: rgb(250, 250, 250);
  }
}

#teacher-education {
  background-color: #e9eef8;
  .head {
    display: flex;
    align-items: center;
    padding-top: 30px;
    h1 {
      color: $darkColor;
      font-family: $sofiaSansSemiBold, sans-serif;
      font-size: $extraLargeFontSize;
      font-weight: normal;
    }
    .filter {
      margin-left: 35px;
      display: flex;
      align-items: center;
      input[type="text"],
      select {
        background: #ffffff;
        border-radius: 31px;
        width: 315px;
        height: 43px;
        border: none;
        padding: 0 20px;
        font-size: $smallFontSize;
        color: $darkColor;
        &::placeholder {
          color: $semiLightGrayColor;
        }
      }
      input[type="text"]{
        background-image: url("../images/admin/teachers/icons/search.svg");
        background-position: 20px center;
        background-repeat: no-repeat;
        background-size: 17px;
        padding-left: 40px;
      }
      select{
        width: 200px;
        margin-left: 10px;
      }
      button.clear{
        background: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 43px;
        height: 43px;
        min-width: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }
  .wrapper:not(.head) {
    padding: 40px 0;
    ul.tabs {
      display: flex;
      align-items: center;
      // gap: 15px;
      margin-bottom: 20px;
      li {
        // gap: 15px;
        margin-right: 15px;
        a {
          font-size: 20px;
          color: $semiLightGrayColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          position: relative;
          &::after {
            content: "";
            width: 0;
            opacity: 0;
            transition: 0.2s;
            height: 2px;
            background: $purpleColor;
            position: absolute;
            top: calc(100% + 3px);
            left: 0;
            max-width: 100%;
            pointer-events: none;
          }
          &.active {
            color: $darkColor;
            &::after {
              width: 100%;
              opacity: 1;
            }
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .no-results{
      margin: 0 auto;
    }
    ul.educations {
      display: flex;
      flex-wrap: wrap;
      // gap: 15px;
      margin-right: -15px;
      width: calc(100% + 15px);
      .MuiSkeleton-root {
        min-height: 250px;
        width: calc(100% / 5 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transform: scale(1);
      }
      > li {
        width: calc(100% / 5 - 15px);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #eee;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;
        .education-menu {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        a {
          display: flex;
          width: 100%;
          height: 100%;
          padding-top: 100px;
          .body {
            // background-image: url('../images/teachers/education/shape.svg');
            // background-position: top center;
            // background-size: 100% auto;
            // background-repeat: no-repeat;
            padding: 15px;
            width: 100%;
            position: relative;
            padding-bottom: 60px;
            .main {
              display: flex;
              align-items: center;
              position: relative;
              z-index: 2;
              margin-top: -20px;
              b {
                font-size: $mediumFontSize;
                color: #fff;
                font-family: $sofiaSansSemiBold, sans-serif;
                font-weight: normal;
                line-height: 1.1;
              }
            }
            ul.footer {
              background: linear-gradient(
                180deg,
                #4a61c9 -80.63%,
                $purpleColor 100%
              );
              border-radius: 10px;
              height: 45px;
              width: 100%;
              bottom: 0;
              left: 0;
              right: 0;
              position: absolute;
              z-index: 2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 15px;
              color: #a3b3ff;
              font-size: 13px;
              li {
                display: flex;
                align-items: center;
                span {
                  width: 33px;
                  height: 33px;
                  background: #fff;
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 5px;
                  border-radius: 50%;
                }
              }
            }
            &::before {
              content: "";
              width: calc(100% + 40px);
              height: calc(100% + 40px);
              background: linear-gradient(
                180deg,
                #7d93f5 -14.61%,
                $purpleColor 100%
              );
              border-radius: 10px;
              position: absolute;
              z-index: 1;
              left: -20px;
              top: 0;
              transform: rotate(-11deg);
            }
          }
          .footer {
          }
        }
        &:hover {
          box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.48);
        }
      }
      @media screen and (max-width: 1700px) {
        .MuiSkeleton-root {
          width: calc(100% / 4 - 15px);
        }
        > li {
          width: calc(100% / 4 - 15px);
        }
      }
      // @media screen and (max-width: 1500px) {
      //   > li {
      //     width: calc(100% / 4 - 15px);
      //   }
      // }
      @media screen and (max-width: 1300px) {
        .MuiSkeleton-root {
          width: calc(100% / 3 - 15px);
        }
        > li {
          width: calc(100% / 3 - 15px);
        }
      }
      @media screen and (max-width: 1024px) {
        .MuiSkeleton-root {
          width: calc(100% / 2 - 15px);
        }
        > li {
          width: calc(100% / 2 - 15px);
        }
      }
    }
  }
}

#teacher-topics,
#teacher-tests,
#teacher-subtopics {
  background-color: #e9eef8;
  .top-skeleton {
    border-radius: 0;
    margin-bottom: 60px;
    .MuiSkeleton-root {
      width: 100%;
      height: 250px;
      transform: scale(1);
    }
  }
  .top {
    background: linear-gradient(
      269.98deg,
      $purpleColor -1.08%,
      rgba(255, 255, 255, 0) 101.4%
    );
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 30px 15px;
    padding-bottom: 65px;
    display: block;
    .wrapper {
      display: flex;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        flex: 1;
        padding-right: 20px;
        .education-content-indicator {
          box-shadow: none;
          margin-right: 10px;
        }
        .info {
          h1 {
            color: $darkColor;
            font-size: $extraLargeFontSize;
            font-family: $sofiaSansSemiBold, sans-serif;
            line-height: 1;
            margin-bottom: 10px;
            font-weight: normal;
          }
          p {
            font-size: $smallFontSize;
            color: $lightPurpleColor;
            font-family: $sofiaSansLight, sans-serif;
          }
        }
      }
      .right {
        width: 100%;
        max-width: fit-content;
        display: flex;
        align-items: center;
        // gap: 40px;
        ul {
          display: flex;
          align-items: center;
          // gap: 40px;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: $ultraLargeFontSize;
            font-family: $sofiaSansSemiBold, sans-serif;
            margin-left: 40px;
            span {
              text-transform: uppercase;
              font-family: inherit;
              font-size: 20px;
              color: inherit;
            }
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        .education-menu {
          margin-left: 40px;
        }
      }
    }
  }
}

#teacher-topics,
#teacher-subtopics {
  .wrapper.bottom {
    > ul {
      margin-top: -35px;
      padding: 15px;
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      // gap: 15px;
      margin-right: -15px;
      width: calc(100% + 15px);
      .MuiSkeleton-root {
        min-height: 250px;
        width: calc(100% / 5 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transform: scale(1);
      }
      > li {
        background-color: #fff;
        border-radius: 3px;
        padding: 15px;
        width: calc(100% / 6 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
        min-height: 320px;
        i.number {
          background: #fff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          width: 46px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #4e66d1;
          font-size: $largeFontSize;
          font-style: normal;
          font-family: $sofiaSansBold;
          position: absolute;
          top: 25px;
          left: 25px;
        }
        img.main {
          width: 100%;
          aspect-ratio: 16/9;
          object-fit: cover;
          object-position: center;
          border-radius: 3px;
          display: block;
          margin-bottom: 10px;
          flex-shrink: 0;
        }
        span.included {
          display: block;
          text-align: center;
          font-size: $smallFontSize;
          color: $lightPurpleColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-bottom: 10px;
        }
        p {
          text-align: center;
          color: $darkColor;
          line-height: 1.4;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-bottom: 40px;
        }
        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;
          flex-shrink: 0;
          width: 100%;
          span {
            display: flex;
            align-items: center;
            color: $semiLightGrayColor;
            font-size: $smallFontSize;
            i {
              background-color: #fff;
              margin-right: 5px;
              padding: 5px;
              border-radius: 50%;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            }
          }
        }
        .hover {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
          transform: translateY(-100%);
          background-color: rgba(255, 255, 255, 0.95);
          transition: 0.2s;
          padding: 15px;
          display: flex;
          flex-direction: column;
          .education-menu {
            margin-left: auto;
          }
          ul {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            li {
              border-radius: 10px;
              width: 100%;
              height: 98px;
              margin: 10px 0;
              overflow: hidden;
              a {
                padding: 0 20px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                font-size: $largeFontSize;
                font-family: $sofiaSansBold;
                span {
                  width: 53px;
                  height: 53px;
                  background: #fff;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                  flex-shrink: 0;
                  img {
                    transition: 0.2s;
                  }
                }
              }
              &.topic {
                a {
                  background: linear-gradient(
                    180deg,
                    #7d93f5 -14.61%,
                    $purpleColor 100%
                  );
                  color: #fff;
                }
              }
              &.test {
                a {
                  background: #f9e0df;
                  color: $darkColor;
                }
              }
              &:hover {
                a {
                  span {
                    img {
                      transform: scale(1.05);
                    }
                  }
                }
              }
            }
          }
          button.hide {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0189ff;
            font-size: $mediumFontSize;
            font-family: $sofiaSansSemiBold, sans-serif;
            cursor: pointer;
            text-transform: initial;
            padding: 0 10px;
            width: fit-content;
            margin: 0 auto;
            img {
              margin-right: 5px;
            }
          }
        }
        &:hover {
          .hover {
            opacity: 1;
            bottom: 0;
            transform: translateY(0);
            pointer-events: all;
            box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.48);
          }
        }
      }
      @media screen and (max-width: 1700px) {
        .MuiSkeleton-root {
          width: calc(100% / 5 - 15px);
        }
        > li {
          width: calc(100% / 5 - 15px);
        }
      }
      @media screen and (max-width: 1500px) {
        .MuiSkeleton-root {
          width: calc(100% / 4 - 15px);
        }
        > li {
          width: calc(100% / 4 - 15px);
        }
      }
      @media screen and (max-width: 1300px) {
        .MuiSkeleton-root {
          width: calc(100% / 3 - 15px);
        }
        > li {
          width: calc(100% / 3 - 15px);
        }
      }
      @media screen and (max-width: 1024px) {
        .MuiSkeleton-root {
          width: calc(100% / 2 - 15px);
        }
        > li {
          width: calc(100% / 2 - 15px);
        }
      }
    }
  }
}

#teacher-tests {
  .wrapper.bottom {
    > ul {
      margin-top: -35px;
      padding: 15px;
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      // gap: 15px;
      margin-right: -15px;
      width: calc(100% + 15px);
      .MuiSkeleton-root {
        min-height: 250px;
        width: calc(100% / 5 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        transform: scale(1);
      }
      > li {
        background-color: #fff;
        border-radius: 3px;
        padding-top: 0;
        width: calc(100% / 6 - 15px);
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;
        .education-menu {
          position: absolute;
          right: 15px;
          top: 15px;
        }
        a {
          padding: 15px;
          padding-top: 50px;
          width: 100%;
          height: 100%;
          display: inherit;
          flex-direction: inherit;
          justify-content: inherit;
          align-items: inherit;
          b {
            font-size: $largeFontSize;
            text-align: center;
            font-family: $sofiaSansSemiBold, sans-serif;
            display: block;
            color: $darkColor;
            font-weight: normal;
          }
          .test-icon {
            height: 120px;
            width: 120px;
            background-image: url("../images/students/tests/icons/test.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            margin-bottom: 10px;
            &::after {
              content: "";
              width: 40px;
              height: 40px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              pointer-events: none;
            }
            &.good::after {
              background-image: url("../images/students/tests/icons/good.svg");
            }
            &.bad::after {
              background-image: url("../images/students/tests/icons/bad.svg");
            }
          }
          p {
            color: $darkColor;
            font-size: $mediumFontSize;
            $sofiaSansSemiBold: "SofiaSans Bold";
            text-align: center;
            line-height: 1.2;
            margin-bottom: 10px;
          }
          ul.results {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
              margin: 0 5px;
              display: flex;
              align-items: center;
              color: $semiLightGrayColor;
              font-size: $smallFontSize;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                background-color: #fff;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                margin-right: 5px;
              }
            }
          }
        }
        &:hover {
          box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.48);
        }
      }
      @media screen and (max-width: 1700px) {
        .MuiSkeleton-root {
          width: calc(100% / 5 - 15px);
        }
        > li {
          width: calc(100% / 5 - 15px);
        }
      }
      @media screen and (max-width: 1500px) {
        .MuiSkeleton-root {
          width: calc(100% / 4 - 15px);
        }
        > li {
          width: calc(100% / 4 - 15px);
        }
      }
      @media screen and (max-width: 1300px) {
        .MuiSkeleton-root {
          width: calc(100% / 3 - 15px);
        }
        > li {
          width: calc(100% / 3 - 15px);
        }
      }
      @media screen and (max-width: 1024px) {
        .MuiSkeleton-root {
          width: calc(100% / 2 - 15px);
        }
        > li {
          width: calc(100% / 2 - 15px);
        }
      }
    }
  }
}

#admin-topic,
#admin-test {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $lightGrayColor;
  display: flex;
  flex-direction: column;
  // gap: 15px;
  .header {
    border-bottom: 1px solid #bdccde;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    height: 112px;
    margin-bottom: 15px;
    .wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      .left {
        h1 {
          font-size: $extraLargeFontSize;
          color: $darkColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          margin-bottom: 5px;
          font-weight: normal;
        }
        p {
          color: $semiLightGrayColor;
          font-size: $smallFontSize;
          font-family: $sofiaSansSemiBold, sans-serif;
          font-weight: normal;
          b {
            color: $darkColor;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            padding-right: 5px;
          }
        }
      }
      ul.navigation {
        margin: 0 50px;
        display: flex;
        align-items: center;
        li {
          margin-right: 20px;
          button,
          p {
            font-size: $smallFontSize;
            color: $semiLightGrayColor;
            text-transform: initial;
            display: flex;
            &.text-out {
              flex-direction: column;
              padding: 5px;
              min-width: initial;
              i {
                width: 32px;
                height: 32px;
                background: #fff;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-bottom: 2px;
              }
            }
          }
          p {
            margin-bottom: 5px;
            font-family: $sofiaSansSemiBold, sans-serif;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        button.save {
          background: $purpleColor;
          border-radius: 31px;
          height: 43px;
          padding: 0 20px;
          color: #fff;
          text-transform: initial;
          font-size: $smallFontSize;
          font-family: $sofiaSansSemiBold, sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          img {
            margin-right: 7px;
          }
        }
        button.close {
          background: #ffffff;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 21.5px;
          width: 43px;
          height: 43px;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: initial;
        }
      }
    }
  }
  > .content {
    display: flex;
    flex: 1;
    // gap: 50px;
    height: calc(100% - 127px);
    .aside {
      background: #e2e8ff;
      border-radius: 0px 20px 20px 0px;
      width: 220px;
      position: relative;
      overflow: hidden;
      padding-bottom: 65px;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      margin-right: 50px;
      flex-shrink: 0;
      ul.list {
        padding: 10px 20px;
        padding-bottom: 50px;
        position: relative;
        z-index: 1;
        overflow: auto;
        flex: 1;
        > li {
          background: #fff;
          border-radius: 0px 15px 0px 0px;
          height: 110px;
          width: 100%;
          margin-bottom: 20px;
          cursor: pointer;
          position: relative;
          .content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            span.number {
              background: $purpleColor;
              border-radius: 50%;
              width: 21px;
              height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-family: $sofiaSansSemiBold, sans-serif;
              position: absolute;
              z-index: 2;
              top: 10px;
              left: 10px;
              font-size: $smallFontSize;
              pointer-events: none;
            }
            .mini-content {
              padding: 10px 35px;
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              max-width: 100%;
              .heading,
              .question {
                &,
                * {
                  text-align: center;
                  font-family: "SofiaSans Bold", sans-serif;
                  // color: #42424e;
                  font-size: $smallFontSize;
                  white-space: nowrap;
                  width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              ul.gallery {
                display: flex;
                justify-content: center;
                // flex-wrap: wrap;
                // gap: 5px;
                width: calc(100% + 2px);
                margin-right: -2px;
                overflow: hidden;
                max-width: 100%;
                margin: 5px 0;
                position: relative;
                li {
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  width: fit-content;
                  border: 1px solid $semiDarkWhiteColor;
                  box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.05);
                  overflow: hidden;
                  padding: 2px;
                  margin-right: 2px;
                  min-width: 30px;
                  max-width: 50px;
                  height: 30px;
                  margin-bottom: 2px;
                  position: relative;
                  > img {
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    object-fit: contain;
                  }
                }
                &::before {
                  content: "";
                  width: 30px;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background-image: linear-gradient(
                    to right,
                    white,
                    rgba(255, 255, 255, 0)
                  );
                  z-index: 1;
                }
                &::after {
                  content: "";
                  width: 30px;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-image: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0),
                    white
                  );
                  z-index: 1;
                }
              }
              .text,
              ul.answers li {
                &,
                * {
                  // color: $semiLightGrayColor;
                  font-size: 13px;
                  text-align: center;
                  line-height: 1;
                  white-space: nowrap;
                  width: 100%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              &.heading-image-text {
                flex-direction: row;
                padding: 10px 20px;
                ul.gallery {
                  margin: 0;
                  margin-right: 2px;
                  width: fit-content;
                  li {
                    width: 60px;
                    height: 60px;
                    margin-right: 0;
                    margin-bottom: 0;
                  }
                }
                .holder {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  // gap: 15px;
                  width: 100%;
                  .heading {
                    // margin-bottom: -10px;
                    p {
                      text-align: left;
                    }
                  }
                  .text {
                    p {
                      text-align: left;
                    }
                  }
                }
              }
              ul.answers {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 5px;
                li {
                  display: flex;
                  align-items: center;
                  background: #ffffff;
                  border: 1px solid $semiDarkWhiteColor;
                  box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
                  border-radius: 3px;
                  padding: 2px;
                  padding-left: 17px;
                  position: relative;
                  min-height: 17px;
                  margin-bottom: 2px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  * {
                    font-size: 10px;
                    width: 100%;
                  }
                  > b {
                    font-family: $sofiaSansBold;
                    position: absolute;
                    left: 2px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $darkColor;
                    width: 13px;
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
              table {
                // border: 1px solid $darkColor;
                th,
                td {
                  border-right: 1px solid $darkColor;
                  border-bottom: 1px solid $darkColor;
                  font-size: 9px;
                  color: $darkColor;
                  font-weight: normal;
                  width: 17px;
                  max-width: 17px;
                  height: 17px;
                  max-height: 17px;
                  text-align: center;
                  margin: 0;
                  padding: 0;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  .var {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    span {
                      margin-bottom: -4px;
                      padding-left: 1px;
                    }
                    i{
                      font-size: 11px;
                      font-style: normal;
                      position: absolute;
                      top: calc(100% + 5px);
                      left: 50%;
                      transform: translateX(-50%);
                    }
                  }
                  &:last-of-type {
                    border-right: none;
                  }
                }
                th {
                  background-color: $lightGrayColor;
                }
                tbody {
                  tr {
                    td {
                      div:not(.var) {
                        margin-top: -6px !important;
                      }
                      &:first-of-type {
                        background-color: $lightGrayColor;
                      }
                    }
                    &:last-of-type {
                      td {
                        border-bottom: none;
                        &:last-of-type {
                          background-color: $lightGrayColor;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ul.options {
            position: absolute;
            top: 5px;
            right: 5px;
            display: flex;
            flex-direction: column;
            // gap: 5px;
            li {
              margin-bottom: 5px;
              button {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: initial;
              }
              &.duplicate {
                button {
                  background: #98a4da;
                }
              }
              &.delete {
                button {
                  background: $redColor;
                }
              }
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
          &::after {
            content: "";
            width: calc(100% + 40px);
            height: calc(100% + 20px);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #cdd5fb;
            position: absolute;
            z-index: -1;
            opacity: 0;
            // transition: 0.2s;
          }
          &::before {
            content: "";
            background: #94a7ff;
            width: 6px;
            position: absolute;
            left: -20px;
            top: -10px;
            bottom: -10px;
            height: calc(100% + 20px);
            transform: translateX(-20px);
            transition: 0.2s;
          }
          &.active {
            cursor: default;
            &::after {
              opacity: 1;
            }
            &::before {
              transform: translate(0);
            }
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #e2e8ff;
        }
        &::-webkit-scrollbar-thumb {
          background: #98a4da;
          border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #8590c1;
        }
      }
      button.new-slide {
        background: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 21.5px;
        height: 43px;
        padding: 0 10px;
        color: $purpleColor;
        font-size: $smallFontSize;
        font-family: $sofiaSansSemiBold, sans-serif;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        text-transform: initial;
        line-height: 1;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        img {
          margin-right: 7px;
        }
      }
      &::after {
        content: "";
        width: calc(100% - 5px);
        height: 100px;
        position: absolute;
        left: 0;
        bottom: 64px;
        right: 5px;
        background-image: linear-gradient(
          to top,
          #e2e8ff,
          rgba(255, 255, 255, 0)
        );
        pointer-events: none;
        z-index: 1;
      }
    }
    .main {
      flex: 1;
      padding-right: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      // gap: 10px;
      .options {
        display: flex;
        align-items: center;
        width: 100%;
        flex-shrink: 0;
        margin-bottom: 10px;
        .left {
          display: flex;
          align-items: center;
          p {
            color: $semiLightGrayColor;
            font-family: $sofiaSansSemiBold, sans-serif;
            font-size: $smallFontSize;
            margin-right: 15px;
          }
          ul.templates {
            display: flex;
            align-items: center;
            // gap: 5px;
            li {
              margin-right: 5px;
              button {
                border-radius: 4px;
                border: 1px solid transparent;
                &.active,
                &:hover {
                  background: #e3e6ee;
                }
                &.active {
                  border: 1px solid #bdccde;
                }
              }
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        ul.right {
          margin-left: auto;
          display: flex;
          align-items: center;
          // gap: 10px;
          li {
            margin-left: 10px;
            button {
              width: 32.59px;
              height: 32.59px;
              background: #ffffff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
              border-radius: 50%;
              min-width: initial;
              padding: 0;
              &.delete {
                background-color: $redColor;
              }
            }
          }
        }
      }
      .content {
        flex: 1;
        background: #ffffff;
        border: 1px solid $semiDarkWhiteColor;
        box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
        border-radius: 4px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: auto;
      }
    }
  }
}

#admin-topic {
  .header {
    .wrapper {
      ul.navigation {
        li {
          button {
            &.text-in {
              background: #fff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
              border-radius: 21.5px;
              align-items: center;
              justify-content: center;
              height: 43px;
              padding: 0 15px;
              line-height: 1;
              font-family: $sofiaSansSemiBold, sans-serif;
              img {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  > .content {
    .aside {
      ul.list {
        li {
          .content {
            .mini-content {
              .heading {
                &,
                * {
                  text-align: center;
                  font-family: "SofiaSans Bold", sans-serif;
                  // color: #42424e;
                  font-size: $smallFontSize;
                }
              }
              ul.gallery {
                display: flex;
                justify-content: center;
                // flex-wrap: wrap;
                // gap: 5px;
                width: calc(100% + 2px);
                margin-right: -2px;
                overflow: hidden;
                max-width: 100%;
                margin: 5px 0;
                position: relative;
                li {
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  width: fit-content;
                  border: 1px solid $semiDarkWhiteColor;
                  box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.05);
                  overflow: hidden;
                  padding: 2px;
                  margin-right: 2px;
                  min-width: 30px;
                  max-width: 50px;
                  height: 30px;
                  margin-bottom: 2px;
                  position: relative;
                  > img {
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    object-fit: contain;
                  }
                }
                &::before {
                  content: "";
                  width: 30px;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background-image: linear-gradient(
                    to right,
                    white,
                    rgba(255, 255, 255, 0)
                  );
                  z-index: 1;
                }
                &::after {
                  content: "";
                  width: 30px;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-image: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0),
                    white
                  );
                  z-index: 1;
                }
              }
              .text {
                &,
                * {
                  // color: $semiLightGrayColor;
                  font-size: 13px;
                  text-align: center;
                  line-height: 1;
                }
              }
              &.heading-image-text {
                flex-direction: row;
                padding: 10px 20px;
                ul.gallery {
                  margin: 0;
                  margin-right: 2px;
                  width: fit-content;
                  li {
                    width: 60px;
                    height: 60px;
                    margin-right: 0;
                    margin-bottom: 0;
                  }
                }
                .holder {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  // gap: 15px;
                  width: 100%;
                  .heading {
                    // margin-bottom: -10px;
                    p {
                      text-align: left;
                    }
                  }
                  .text {
                    p {
                      text-align: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .main {
      .content {
        .heading,
        .text {
          width: 100%;
          border-radius: 4px;
          border: 1px solid transparent;
          * {
            margin: 0;
            padding: 0;
          }
          p {
            padding: 10px;
          }
          &:hover {
            background: #f6f7fa;
            border: 1px solid #0189ff;
          }
        }
        .heading {
          margin-bottom: 10px;
          p {
            text-align: center;
            font-family: $sofiaSansSemiBold, sans-serif;
            color: $darkColor;
            font-size: $extraLargeFontSize;
          }
        }
        .text {
          p {
            color: $semiLightGrayColor;
            font-size: $mediumFontSize;
            text-align: center;
            line-height: 1.4;
          }
        }
        &.heading-text {
          .text {
          }
        }
        ul.gallery {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          // gap: 5px;
          width: calc(100% + 5px);
          margin-right: -5px;
          li {
            justify-content: center;
            align-items: center;
            display: flex;
            // flex: 1;
            // aspect-ratio: 1/1;
            width: fit-content;
            border: 1px solid $semiDarkWhiteColor;
            box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.05);
            overflow: hidden;
            padding: 5px;
            margin-right: 5px;
            min-width: 100px;
            min-height: 100px;
            max-width: 300px;
            max-height: 300px;
            margin-bottom: 15px;
            position: relative;
            button {
              background: $redColor;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: initial;
              position: absolute;
              top: 2.5px;
              right: 2.5px;
              padding: 0;
              img,
              span {
                pointer-events: none;
              }
            }
            > img {
              max-width: 100%;
              max-height: 100%;
              display: block;
              object-fit: contain;
            }
          }
        }

        &.heading-images-text {
          ul.gallery {
            margin-bottom: 10px;
          }
        }
        &.images-text {
          .gallery {
            margin-bottom: 10px;
          }
        }
        &.heading-images-text,
        &.images-text {
          ul.gallery {
            li {
              min-width: 150px;
              min-height: 150px;
            }
          }
        }
        &.heading-image-text {
          flex-direction: row;
          ul.gallery {
            margin-right: 10px;
            width: fit-content;
            li {
              max-height: 300px;
              width: 300px;
              height: 300px;
              margin-right: 0;
              margin-bottom: 0;
            }
          }
          .holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // gap: 15px;
            width: 100%;
            .heading {
              // margin-bottom: -10px;
              p {
                text-align: left;
              }
            }
            .text {
              p {
                text-align: left;
              }
            }
          }
        }
        .non-uploaded-image {
          background-image: url("../images/admin/topic/icons/image.svg");
          background-size: 60px 45px;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #c3d0e1;
          min-width: 100px;
          min-height: 100px;
          display: block;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: #b8c6d9;
            // background-size: 48px 37px;
          }
        }
      }
    }
  }
}

#admin-test {
  > .content {
    .main {
      .options {
        .left {
          ul.additional-options {
            display: flex;
            align-items: center;
            margin-left: 40px;
            li {
              display: flex;
              flex-direction: column;
              margin-right: 15px;
              label {
                color: $semiLightGrayColor;
                font-family: $sofiaSansSemiBold, sans-serif;
                font-size: $smallFontSize;
                margin-bottom: 2px;
              }
              .MuiInputBase-root,
              > input,
              &.size input {
                background: #e3e6ee;
                border: 1px solid #bdccde;
                border-radius: 4px;
                color: $semiLightGrayColor;
                font-size: $smallFontSize;
                font-family: $sofiaSansMedium, sans-serif;
                height: 25px;
                padding-top: 0;
                padding-bottom: 0;
                div {
                  min-height: initial;
                  padding-top: inherit;
                  padding-bottom: inherit;
                }
                fieldset {
                  border: none;
                  border-width: 0;
                }
              }

              > input,
              &.size input {
                background-color: #fff;
                padding: 0 5px;
                max-width: 50px;
              }

              &.size {
                display: flex;
                align-items: flex-end;
                flex-direction: row;
                margin-left: 15px;
                span {
                  padding: 0 10px;
                  height: 25px;
                  display: flex;
                  align-items: center;
                  font-size: $mediumFontSize;
                  color: $semiLightGrayColor;
                  font-family: $sofiaSansMedium, sans-serif;
                }
                .col {
                  display: flex;
                  flex-direction: column;
                }
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
      .content {
        align-items: center;
        justify-content: center;
        .question {
          width: 100%;
          border-radius: 4px;
          border: 1px solid transparent;
          margin-bottom: 10px;
          * {
            margin: 0;
            padding: 0;
          }
          p {
            padding: 10px;
            text-align: center;
            font-family: $sofiaSansSemiBold, sans-serif;
            color: $darkColor;
            font-size: $extraLargeFontSize;
          }
          &:hover {
            background: #f6f7fa;
            border: 1px solid #0189ff;
          }
        }
        ul.answers {
          // flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          li {
            background: #ffffff;
            border: 1px solid $semiDarkWhiteColor;
            box-shadow: 0px 0px 28px rgba(120, 132, 176, 0.17);
            border-radius: 4px;
            width: 100%;
            flex: 1;
            margin-bottom: 10px;
            max-height: 400px;
            display: flex;
            position: relative;
            > span {
              position: absolute;
              top: 50%;
              left: 10px;
              transform: translateY(-50%);
              display: block;
              width: 10px;
              text-align: center;
              font-size: $smallFontSize;
              color: $darkColor;
              font-family: $sofiaSansSemiBold, sans-serif;
              pointer-events: none;
            }
            .ck {
              min-height: 40px;
              max-height: 100%;
              height: 100%;
              width: 100%;
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              padding-left: 27px;
              padding-right: 55px;
              p {
                width: 100%;
                color: $darkColor;
                font-size: $smallFontSize;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            .answer-options {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              display: flex;
              align-items: center;
              button {
                padding: 0;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                min-width: initial;
                &.value {
                  background-color: #c3d0e1;
                  &.green {
                    background-color: $greenColor;
                  }
                }
                &.delete {
                  background-color: $redColor;
                  margin-left: 5px;
                  img {
                    max-width: 12px;
                  }
                }
              }
            }
          }
        }
        p.message {
          text-align: left;
          width: 100%;
          margin-top: 10px;
          color: $darkColor;
          font-size: $smallFontSize;
          span {
            color: $redColor;
          }
        }
        .table {
          // border: none;
          table {
            // border: 1px solid $darkColor;
            th,
            td {
              border-right: 1px solid $darkColor;
              border-bottom: 1px solid $darkColor;
              font-size: $mediumFontSize;
              color: $darkColor;
              font-weight: normal;
              width: 60px;
              height: 60px;
              text-align: center;
              input {
                padding: 5px 0;
                border: none;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                width: 100%;
                height: 100%;
                text-align: center;
              }
              .var {
                display: flex;
                align-items: flex-end;
                text-align: center;
                justify-content: center;
                position: relative;
                span {
                  font-size: 14px;
                  margin-bottom: -3px;
                  display: block;
                  padding-left: 1px;
                }
                i{
                  font-size: 11px;
                  font-style: normal;
                  position: absolute;
                  top: calc(100% + 5px);
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
              &:last-of-type {
                border-right: none;
              }
            }
            th {
              background-color: $lightGrayColor;
              &.empty{
                i{
                  font-size: 11px;
                  font-style: normal;
                  line-height: 1;
                  // display: block;
                }
                hr{
                  background-color: $darkColor;
                }
              }
            }
            tbody {
              tr {
                td {
                  &:first-of-type {
                    background-color: $lightGrayColor;
                  }
                }
                &:last-of-type {
                  td {
                    border-bottom: none;
                    &:last-of-type {
                      background-color: $lightGrayColor;
                    }
                  }
                }
              }
            }
          }
        }
        button.add-answer {
          width: 100%;
          background: #c3d0e1;
          border-radius: 4px;
          color: #fff;
          font-size: $smallFontSize;
          font-family: $sofiaSansSemiBold, sans-serif;
          height: 90px;
          margin-top: 20px;
          text-transform: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 10px;
          }
        }
        &.table-question-open-answer {
          flex-direction: column;
          .bottom{
            display: flex;
            justify-content: center;
            width: 100%;
            .table {
              padding-right: 20px;
            }
            .answers {
              display: flex;
              flex-direction: column;
              flex: 1;
              max-width: 600px;
            }
          }
        }
      }
    }
  }
}

#admin-teachers,
#admin-students {
  background: #e9eef8;
  .wrapper {
    padding: 40px 0;
    .head {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      h1 {
        color: $darkColor;
        font-family: $sofiaSansSemiBold, sans-serif;
        font-size: $extraLargeFontSize;
        font-weight: normal;
      }
      .filter {
        margin-left: 35px;
        display: flex;
        align-items: center;
        input[type="text"],
        select {
          background: #ffffff;
          border-radius: 31px;
          width: 315px;
          height: 43px;
          border: none;
          padding: 0 20px;
          font-size: $smallFontSize;
          color: $darkColor;
          &::placeholder {
            color: $semiLightGrayColor;
          }
        }
        input[type="text"]{
          background-image: url("../images/admin/teachers/icons/search.svg");
          background-position: 20px center;
          background-repeat: no-repeat;
          background-size: 17px;
          padding-left: 40px;
        }
        select{
          width: 200px;
          margin-left: 10px;
        }
        button.clear{
          background: #fff;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          width: 43px;
          height: 43px;
          min-width: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
      }
      >button.move{
        width: 43px;
        height: 43px;
        min-width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      >button.add {
        background: $purpleColor;
        border-radius: 31px;
        width: 140px;
        height: 43px;
        color: #fff;
        font-family: $sofiaSansSemiBold, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        text-transform: initial;
        font-size: $smallFontSize;
        line-height: 1;
        img {
          margin-right: 10px;
        }
      }
      .left-auto{
        margin-left: auto !important;
      }
    }
    .MuiSkeleton-root {
      width: 100%;
      transform: scale(1);
      margin-bottom: 10px;
      height: 60px;
      &.thead {
        height: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

#admin-teachers,
#admin-students,
#statistics {
  .wrapper {
    table {
      width: 100%;
      border: none;
      thead {
        tr {
          th {
            text-align: left;
            text-transform: uppercase;
            font-size: $smallFontSize;
            color: $semiLightGrayColor;
            font-weight: normal;
            padding: 10px 15px;
          }
        }
      }
      tbody {
        tr {
          td {
            span.status {
              display: flex;
              align-items: center;
              justify-content: center;
              width: fit-content;
              border-radius: 31px;
              padding: 7px 10px;
              background-color: #ffad31;
              color: #fff;
              font-size: inherit;
              &.blue {
                background-color: #209cff;
              }
            }
            > div {
              padding: 15px;
              height: 64px;
              background-color: #fff;
              border: 1px solid $semiDarkWhiteColor;
              margin-bottom: 5px;
              color: $darkColor;
              font-size: $smallFontSize;
              display: flex;
              align-items: center;
              img.profile-picture {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                flex-shrink: 0;
                margin-right: 10px;
                background-color: #eee;
                object-fit: cover;
                object-position: center;
              }
            }
            &:not(:first-of-type) {
              > div {
                border-left: none;
              }
            }
            &:not(:last-of-type) {
              > div {
                border-right: none;
              }
            }
            &:first-of-type {
              > div {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
            }
            &:last-of-type {
              > div {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
      }
      .centered {
        text-align: center;
        div {
          justify-content: center;
          text-align: center;
          button {
            width: 40px;
            height: 40px;
            min-width: initial;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

#admin-students,
#statistics {
  .wrapper {
    table {
      tbody {
        tr {
          td {
            div {
              .progress {
                display: flex;
                align-items: center;
                background: #209cff;
                border-radius: 5px;
                min-width: 100px;
                max-width: 100px;
                height: 35px;
                padding: 10px;
                position: relative;
                overflow: hidden;
                i {
                  color: #fff;
                  font-size: $smallFontSize;
                  font-style: normal;
                  display: flex;
                  align-items: center;
                  position: relative;
                  z-index: 2;
                  img {
                    margin-right: 5px;
                  }
                }
                &::after {
                  content: "";
                  min-width: 5px;
                  width: inherit;
                  background-color: #1a8ce8;
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  z-index: 1;
                }
                &.green {
                  background-color: $darkGreenColor;
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#statistics {
  background-color: #e9eef8;
  .wrapper {
    padding: 20px 0;
    padding-bottom: 5px;
    .banner {
      border-radius: 10px;
      background-color: $purpleColor;
      padding: 15px;
      padding-bottom: 0;
      margin-bottom: 30px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .top {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          padding-right: 40px;
          display: flex;
          align-items: center;
          max-width: 50%;
          .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
            .MuiOutlinedInput-notchedOutline {
            border: none !important;
          }
          .MuiInputBase-root {
            // background-color: rgba(227, 197, 148, 0.6);
            max-width: 100%;
            .MuiSelect-select {
              width: 100%;
              // padding: 9.5px 14px;
              padding-right: 50px;
              font-family: $sofiaSansMedium, sans-serif;
              color: #fff;
              font-size: $extraLargeFontSize;
              // display: flex;
              // align-items: center;
              min-height: initial;
            }
            svg {
              color: #fff;
              width: 50px;
              height: 50px;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          width: fit-content;
          flex-shrink: 0;
          svg[viewBox] {
            width: 100px !important;
            height: 100px !important;
          }
          .results {
            margin-left: 20px;
            b {
              font-family: $sofiaSansSemiBold, sans-serif;
              font-size: 55px;
              color: #fff;
              display: block;
              margin-bottom: 5px;
              font-weight: normal;
            }
            .indicator {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: $smallFontSize;
              font-family: $sofiaSansMedium, sans-serif;
              span {
                width: 17px;
                height: 17px;
                border-radius: 4px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      ul.bottom {
        background-color: #4059c9;
        height: 58px;
        padding: 0 15px;
        padding-bottom: 0;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        display: flex;
        flex-shrink: 0;
        padding-top: 7px;
        li {
          display: flex;
          a {
            color: #fff;
            font-size: $mediumFontSize;
            font-family: $sofiaSansSemiBold, sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            padding-bottom: 7px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            &.active {
              color: $darkColor;
              background-color: #e9eef8;
            }
          }
        }
      }
    }
  }
}

#chose,
#login,
#register {
  position: relative;
  .software-partner {
    position: absolute;
    bottom: 15px;
    right: 15px;
    img {
      max-width: 100%;
      max-width: 100px;
    }
  }
}

#chose {
  background-image: url("../images/auth/chose/bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    padding: 40px 0;
    max-width: 820px;
    .school {
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 80px;
        max-height: 80px;
        display: block;
        margin-right: 10px;
      }
      h2 {
        color: $darkColor;
        font-size: $largeFontSize;
        font-family: $sofiaSansBold, sans-serif;
      }
    }
    b {
      font-family: $sofiaSansSemiBold, sans-serif;
      font-weight: normal;
      color: #fff;
      display: block;
      text-align: center;
      position: relative;
      z-index: 2;
      text-transform: uppercase;
      font-size: $extraLargeFontSize;
      margin-bottom: 10px;
      &::before {
        content: "";
        width: 344px;
        height: 61px;
        background-image: url("../images/auth/chose/heading-shape.png");
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
    h1 {
      text-align: center;
      color: $darkColor;
      font-family: $sofiaSansSemiBold, sans-serif;
      font-weight: normal;
      font-size: $ultraLargeFontSize;
      margin-bottom: 30px;
    }
    > p {
      text-align: center;
      color: $darkColor;
      font-size: $smallFontSize;
      line-height: 1.2;
      margin-bottom: 70px;
    }
    ul {
      display: flex;
      li {
        flex: 1;
        margin-right: 15px;
        button {
          padding: 0;
          border-radius: 25px;
          border: 1px solid #e4e4ed;
          background-color: #f8f9ff;
          text-transform: initial;
          overflow: hidden;
          a {
            padding: 20px;
            min-height: 225px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              height: 95px;
              opacity: 1;
              transition: 0.2s;
              margin-bottom: 10px;
            }
            h5 {
              font-family: $sofiaSansSemiBold, sans-serif;
              font-weight: normal;
              color: $darkColor;
              font-size: $mediumFontSize;
              text-align: center;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
            p {
              color: $darkColor;
              font-family: $sofiaSansLight, sans-serif;
              font-size: $smallFontSize;
              text-align: center;
              line-height: 1.4;
            }
          }
          &:hover {
            border-color: $purpleColor;
            background-color: #f6f5ff;
            a {
              img {
                height: 0;
                opacity: 0;
                margin-bottom: 0;
              }
            }
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

#login,
#register {
  background-image: url("../images/auth/login/bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      width: 100%;
      max-width: 530px;
      background-color: #fff;
      border-radius: 2px;
      padding: 30px 40px;
      overflow: hidden;
      box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.2);
      ul.tabs {
        display: flex;
        border-bottom: 1px solid $purpleColor;
        margin: -30px -40px;
        margin-bottom: 40px;
        li {
          flex: 1;
          position: relative;
          button {
            text-transform: initial;
            width: 100%;
            height: 100%;
            padding: 0;
            border: none;
            border-radius: 0;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              width: 100%;
              img {
                margin-right: 10px;
              }
              .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                span {
                  text-align: left;
                  color: $darkColor;
                  font-size: $mediumFontSize;
                  font-family: $sofiaSansLight, sans-serif;
                  line-height: 1;
                }
                b {
                  text-align: left;
                  text-transform: uppercase;
                  color: $darkColor;
                  font-family: $sofiaSansSemiBold, sans-serif;
                  font-weight: normal;
                  font-size: 21px;
                  line-height: 1;
                }
              }
            }
          }
          &.active {
            button {
              background-color: $purpleColor;
              a {
                img {
                  filter: invert(100%) sepia(100%) saturate(0%)
                    hue-rotate(288deg) brightness(250%) contrast(102%);
                }
                .right {
                  span,
                  b {
                    color: #fff;
                  }
                }
              }
            }
            &::after {
              content: "";
              width: 27px;
              height: 11px;
              background-position: top center;
              background-size: contain;
              background-repeat: no-repeat;
              background-image: url("../images/auth/login/bottom-pointer.png");
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              animation: swing-in-top-fwd 0.5s
                cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
            }
            @keyframes swing-in-top-fwd {
              0% {
                -webkit-transform: rotateX(-100deg);
                transform: rotateX(-100deg);
                -webkit-transform-origin: top;
                transform-origin: top;
                opacity: 0;
              }
              100% {
                -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
                -webkit-transform-origin: top;
                transform-origin: top;
                opacity: 1;
              }
            }
          }
        }
      }
      h1 {
        color: $darkColor;
        font-size: 24px;
        font-family: $sofiaSansSemiBold, sans-serif;
        font-weight: normal;
        text-align: center;
        margin-bottom: 5px;
      }
      p {
        color: $darkColor;
        font-size: $mediumFontSize;
        font-family: $sofiaSansLight, sans-serif;
        text-align: center;
        line-height: 1.4;
        margin-bottom: 20px;
      }
      .fields {
        padding: 0 20px;
        .col {
          margin-bottom: 20px;
          input {
            height: 53px;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .remember {
            display: flex;
            align-items: center;
            input {
              margin-right: 5px;
              width: 15px;
              height: 15px;
            }
            label {
              padding-bottom: 0;
              font-family: $sofiaSansRegular;
            }
          }
          a {
            color: $darkColor;
            text-decoration: underline;
            font-size: $smallFontSize;
          }
        }
        > button {
          width: 100%;
          height: 52px;
          background-color: $purpleColor;
          color: #fff;
          font-size: $smallFontSize;
          font-family: $sofiaSansSemiBold, sans-serif;
          text-transform: initial;
          margin-top: 20px;
        }
      }
      .no-profile {
        box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.3);
        padding: 20px 40px;
        margin: -30px -40px;
        width: calc(100% + 80px);
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding: 12px 20px;
          border-radius: 30px;
          box-shadow: 0px 0px 35px rgba(120, 132, 176, 0.2);
          color: $darkColor;
          font-size: $smallFontSize;
          a {
            text-decoration: underline;
            color: $linkBlueColor;
          }
        }
      }
    }
  }
}

#register {
  .wrapper {
    form {
      .fields {
        .col {
          margin-bottom: 20px !important;
          flex: 1;
          select {
            height: 53px;
          }
          &.small {
            max-width: 70px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

#student-profile {
  background-color: #e9eef8;
  padding: 15px 0;
  display: flex;
  .wrapper {
    flex: initial 1;
    background-color: #fff;
    border: 1px solid #dde6ed;
    border-radius: 4px;
    padding: 25px;
    display: flex;
    .mobile-menu{
      display: none;
    }
    button {
      min-width: 190px;
      padding: 0 20px;
      height: 40px;
      background: $purpleColor;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: initial;
      color: #fff;
      font-size: $smallFontSize;
      font-family: $sofiaSansSemiBold, sans-serif;
      line-height: 1;
      img {
        margin-right: 5px;
      }
    }
    .holder {
      flex: 1;
      padding-right: 40px;
      .col {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        &:last-of-type {
          padding-right: 0;
        }
      }
      .row {
        display: flex;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .col {
        margin-bottom: 80px;
        b {
          font-size: $mediumFontSize;
          font-family: $sofiaSansSemiBold, sans-serif;
          display: block;
          color: $purpleColor;
          margin-bottom: 20px;
        }
        .user-main {
          align-items: center;
          picture {
            width: 115px;
            height: 115px;
            position: relative;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
            cursor: pointer;
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: inherit;
            }
            &::after {
              content: "";
              width: calc(100% - 11px);
              height: calc(100% - 11px);
              border-radius: 50%;
              border: 1px solid #fff;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              z-index: 2;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              background-color: #fff;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
              border-radius: 50%;
              position: absolute;
              bottom: 7px;
              right: 7px;
              z-index: 3;
              transition: 0.2s;
            }
            &:hover {
              span {
                width: 50px;
                height: 50px;
                right: calc(50% - 50px / 2);
                bottom: calc(50% - 50px / 2);
              }
            }
          }
          .col {
            input,
            select {
              border: none;
              border-bottom: 1px solid rgba(255, 255, 255, 0);
              width: 100%;
              padding: 2px;
              cursor: pointer;
              &:focus,
              &.validate {
                border-bottom-color: $lightPurpleColor;
              }
              &:hover,
              &:focus,
              &.validate {
                background-color: $lightGrayColor;
              }
            }
            .email {
              input {
                color: $linkBlueColor;
                font-size: $smallFontSize;
                margin-bottom: 2px;
                font-style: normal;
              }
            }
            .name {
              input {
                color: $darkColor;
                font-size: $largeFontSize;
                font-family: $sofiaSansSemiBold, sans-serif;
                margin-bottom: 2px;
              }
            }
            .class {
              pointer-events: none;
              select {
                color: $semiDarkGrayColor;
                font-size: $smallFontSize;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: "";
                &::-ms-expand {
                  display: none;
                }
              }
            }
            .email,
            .name,
            .class {
              width: 100%;
              cursor: pointer;
            }
          }
        }
        hr {
          margin: 20px 0;
        }
        .group {
          h6 {
            font-size: 16px;
            font-family: $sofiaSansSemiBold, sans-serif;
            color: $darkColor;
            margin-bottom: 5px;
          }
          p {
            font-size: 14px;
            color: $semiDarkGrayColor;
            margin-bottom: 15px;
          }
          &.delete-profile {
            button {
              background-color: $redColor;
            }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        padding-right: 0;
      }
      ul.educations {
        li {
          background-color: $purpleColor;
          padding: 25px;
          border-radius: 4px;
          margin-bottom: 10px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              padding-right: 40px;
              h4 {
                color: #fff;
                font-size: 25px;
                font-family: $sofiaSansSemiBold, sans-serif;
                line-height: 1;
                font-weight: normal;
              }
            }
            .right {
              display: flex;
              align-items: center;
              width: fit-content;
              flex-shrink: 0;
              svg[viewBox] {
                width: 100px !important;
                height: 100px !important;
              }
              .results {
                margin-left: 20px;
                b {
                  font-family: $sofiaSansSemiBold, sans-serif;
                  font-size: 55px;
                  color: #fff;
                  display: block;
                  margin-bottom: 5px;
                  font-weight: normal;
                }
                .indicator {
                  display: flex;
                  align-items: center;
                  color: #fff;
                  font-size: $smallFontSize;
                  font-family: $sofiaSansMedium, sans-serif;
                  span {
                    width: 17px;
                    height: 17px;
                    border-radius: 4px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
          .progress {
            max-width: calc(100% - 40px);
            min-width: 20px;
            height: 11px;
            background: linear-gradient(
              269.98deg,
              #fff -1.08%,
              $purpleColor 100.87%
            );
            border-radius: 10px;
            position: relative;
            padding-right: 20px;
            margin-top: 20px;
            span {
              position: absolute;
              left: calc(100% + 5px);
              font-size: $mediumFontSize;
              color: #fff;
              top: 50%;
              transform: translateY(-50%);
              font-family: $sofiaSansSemiBold, sans-serif;
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .MuiSkeleton-root {
          width: 100%;
          height: 180px;
          transform: scale(1);
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        p.no-data {
          color: $darkColor;
          font-size: $smallFontSize;
        }
      }
      ul.certificates {
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #dde6ed;
          border-radius: 4px;
          padding: 10px;
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 300px;
          .left {
            padding-right: 20px;
            display: flex;
            align-items: flex-start;
            img {
              margin-right: 10px;
            }
            .right-part {
              h6 {
                color: $darkPurpleColor;
                font-size: $smallFontSize;
                font-weight: normal;
                margin-bottom: 5px;
              }
              span {
                font-size: $smallFontSize;
                color: #aeb7c9;
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            margin-left: auto;
            a {
              width: 28px;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              border: 1px solid #aeb7c9;
              border-radius: 50%;
              margin: 0 2.5px;
              &:hover {
                background-color: #f1f4fa;
              }
            }
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .MuiSkeleton-root {
          width: 100%;
          max-width: 300px;
          height: 74px;
          transform: scale(1);
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        @media screen and (max-width: 760px) {
          .MuiSkeleton-root {
            max-width: 500px;
          }
        }
        p.no-data {
          color: $darkColor;
          font-size: $smallFontSize;
        }
      }
    }
  }
}

#not-found {
  background-color: $purpleColor;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../images/app/404/bg.png");
  background-position: center;
  background-size: auto 500px;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 91;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .wrapper {
    padding: 60px 0;
    h1 {
      color: #fff;
      font-family: $sofiaSansBold, sans-serif;
      font-size: 160px;
      text-align: center;
    }
    p {
      font-size: $mediumFontSize;
      font-family: $sofiaSansMedium;
      text-align: center;
      color: #fff;
      margin-bottom: 40px;
      padding: 0 200px;
    }
    a {
      background-color: #fff !important;
      color: $purpleColor;
      font-size: $smallFontSize;
      text-transform: initial;
      font-family: "SofiaSans SemiBold", sans-serif;
      width: 160px;
      height: 43px;
      box-shadow: 0px 0px 38px rgba(45, 51, 101, 0.3);
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 0;
      margin: 0 auto;
    }
  }
}

$smallFontSize: 13px;
$mediumFontSize: 16px;
$largeFontSize: 22px;
$extraLargeFontSize: 28px;
$ultraLargeFontSize: 35px;
@media screen and (max-width: 1024px) {
  .success-message, 
  .error-message {
    width: calc(100% - 30px);
    font-size: $smallFontSize;
  }

  .full-screen {
    padding: 7.5px;
    .MuiSkeleton-root{
      margin-bottom: 5px;
    }
  }
  .overlay {
    padding: 15px;
    &.question,
    &.change-password{
      .modal {
        .body {
          b{
            font-size: $largeFontSize;
          }
          p{
            font-size: $smallFontSize;
          }
        }
      }
    }

    &.change-password{
     .modal{
      width: 100%;
     }
    }

    &.gallery{
      padding: 5px;
      span.prev,
      span.next{
        z-index: 2;
        width: 30px;
        height: 30px;
        background-size: 10px auto;
      }
      span.close{
        z-index: 2;
        width: 25px;
        height: 25px;
        background-size: contain;
        top: 20px;
        right: 20px;
      }
      .modal{
        padding: 20px 5px;
        width: 100%;
        height: 100%;
      }
    }
  }

  #chose {
    .wrapper {
      padding-bottom: 65px;
      .school {
        margin-bottom: 30px;
        img {
          max-width: 40px;
          max-height: 40px;
        }
        h2 {
          font-size: $largeFontSize;
        }
      }
      b {
        font-size: $extraLargeFontSize;
        &::before {
          height: 45px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      h1 {
        font-size: $ultraLargeFontSize;
      }
      > p {
        display: none;
      }
      ul {
        flex-direction: column;
        max-width: 500px;
        margin: 0 auto;
        li {
          flex: 1;
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          button {
            width: 100%;
            a {
              padding: 20px 15px;
              min-height: initial;
              img {
                height: 65px;
              }
              h5 {
                font-size: $mediumFontSize;
              }
              p {
                font-size: $smallFontSize;
              }
            }
          }
        }
      }
    }
  }

  #student-topics,
  #student-tests {
    .wrapper {
      .top {
        .left {
          h1 {
            font-size: $extraLargeFontSize;
          }
          .MuiInputBase-root {
            .MuiSelect-select {
              font-size: $mediumFontSize;
            }
          }
        }
      }
    }
  }

  #topic {
    .wrapper{
      width: calc(100% - 15px);
    }
    .header {
      .wrapper {
        padding: 10px 0;
        .left {
          .heading{
            display: none;
          }
          p{
            font-size: $mediumFontSize;
          }
        }
      }
    }
    .navigation-holder{
      margin: 0px 5px;
      .navigation{
        padding: 10px 5px;
        ul{
          li{
            font-size: $largeFontSize;
            padding: 1px 9px;
          }
        }
      }
    }
    .main{
      padding-bottom: 5px;
      .wrapper{
        padding-bottom: 45px;
        position: relative;
        ul.container{
          margin: 0 5px;
          >li{
            h1.child{
              font-size: $extraLargeFontSize;
            }
            p.child{
              font-size: $smallFontSize;
            }
            &.heading-image-text{
              img{               
                max-height: 200px;
              }
            }
            .last-slide{
              >img{
                width: 150px;
              }
              p{
                margin-bottom: 25px;
              }
              button{
                a{
                  width: 170px;
                  height: 40px;
                }
              }
            }
          }
        }
        button{
          &.changer{
            height: 40px;
            bottom: 0;
            width: calc(50% - 2.5px);
            position: absolute;
            background-size: 25px 25px;
            &.prev{
              left: 0;
            }
            &.next{
              right: 0;
            }
          }
        }
      }
    }
  }

  #test {
    .wrapper{
      width: calc(100% - 15px);
    }
    .header{
      margin-bottom: 5px;
      .wrapper{
        padding: 5px 0;
      }
    }
    .main {
      margin-bottom: 5px;
      .wrapper {
        padding-bottom: 45px;
        position: relative;
        ul.container{
          margin: 0;
          >li{
            .question{
              margin-bottom: 5px;
              h1{
                font-size: $largeFontSize;
              }
              ul{
                width: 100%;
              }
            }
            .answers{
              padding: 5px;
              .table{
                padding-right: 10px;
                min-width: 290px;
                table{
                  td,
                  th{
                    width: 40px;
                    height: 40px;
                    font-size: $smallFontSize;
                    .var{
                      i{
                        font-size: 9px;
                        top: calc(100% + 2px);
                      }
                    }
                  }
                  th.empty{
                    i{
                      font-size: 9px;
                      line-height: 1;
                      display: block;
                    }
                  }
                }
              }
              ul{
                &.type-list{
                  li{
                    padding-left: 25px;
                    span.code{
                      font-size: $smallFontSize;
                      width: 15px;
                      left: 8px;
                    }
                    p{
                      font-size: $smallFontSize;
                    }
                  }
                }
              }
            }
            &.text-text{
              .answers{
                ul{
                  &.type-list{
                    li{
                     margin-bottom: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        button{
          &.changer{
            height: 40px;
            bottom: 0;
            width: calc(50% - 2.5px);
            position: absolute;
            background-size: 25px 25px;
            &.prev{
              left: 0;
            }
            &.next{
              right: 0;
            }
          }
        }
      }
    }
    .footer{
      padding-bottom: 5px;
      .wrapper{
        padding: 5px;
        ul{
          overflow: auto;
          flex-wrap: nowrap;
          justify-content: flex-start;
          -ms-overflow-style: none;
          scrollbar-width: none;
          li{
            flex-shrink: 0;
            border-radius: 4px;
            padding: 3px;
            width: 40px;
            margin-right: 2.5px;
            b{
              margin-bottom: 2px;
            }
            &.text-text{
              .answers{
                span{
                  margin-bottom: 1px;
                }
              }
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  #result {
    .wrapper{
      padding: 20px 15px;
      .body{
        ul{
          li{
            margin-right: 45px;
            img{
              width: 40px;
              height: 40px;
            }
            .info{
              b{
                font-size: $extraLargeFontSize;
              }
              span{
                font-size: $smallFontSize;
              }
            }
          }
        }
        .holder{
          h6{
            margin-bottom: -20px;
            font-size: $mediumFontSize;
          }
          h2{
            font-size: 50px;
            span{
              font-size: 75px;
            }
          }
        }
        &::after{
          width: 280px;
          height: 215px;
        }
      }
      .footer{
        button{
          a{
            width: 190px;
          }
        }
      }
    }
    &::before{
      height: 51px;
      width: 93px;
    }
    &::after{
      width: 108px;
      height: 75px;
    }
  }

  #student-profile {
    padding: 0;
    .wrapper{
      width: 100%;
      border-radius: 0;
      border: none;
      padding: 30px 15px;
      flex-direction: column;
      .mobile-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        b{
          color: $darkColor;
          font-family: $sofiaSansSemiBold, sans-serif;
          font-size: $extraLargeFontSize;
          display: block;
          margin-bottom: 30px;
        }
        ul{
          margin: 0 -15px;
          margin-bottom: 30px;
          width: calc(100% + 30px);
          border-top: 1px solid #AEAEAE;
          li{
            border-bottom: 1px solid #AEAEAE;
            button{
              padding: 20px 15px;
              height: auto;
              background-color: rgba(255, 255, 255, 0);
              color: $darkColor;
              border-radius: 0;
              width: 100%;
              justify-content: flex-start;
              font-weight: normal;
              img{
                width: 22px;
                margin-right: 10px;
                display: block;
              }
              &.forward{
                background-image: url('../images/mobile/profile/icons/right-arrow.svg');
                background-position: calc(100% - 10px) center;
                background-repeat: no-repeat;
                background-size: 10px auto;
                padding-right: 30px;
              }
            }
          }
        }
        button.logout{
          background-color: #EFF1F9;
          height: 50px;
          color: $darkColor;
          width: fit-content;
          margin: 0 auto;
          margin-top: auto;
          img{
            width: 18px;
            margin-right: 7px;
          }
        }
      }
      .holder{
        >.col{
          display: none;
          &.show{
            display: flex;
          }
        }
        .col{
          padding-right: 0;
          .user-main{
            picture{
              width: 82px;
              height: 82px;
              span{
                width: 25px;
                height: 25px;
                bottom: 2px;
                right: 2px;
                img{
                  width: 15px;
                }
              }
            }
            .col{
              .email{
                input{
                  font-size: $smallFontSize;
                }
              }
              .name{
                input{
                  font-size: $largeFontSize;
                }
              }
            }
          }
        }
        ul.educations{
          li{
            padding: 5px;
            .top{
              .left{
                padding-right: 10px;
                .education-content-indicator{
                  display: none;
                }
                h4{
                  font-size: $mediumFontSize;
                }
              }
              .right{
                svg[viewBox]{
                  width: 50px !important;
                  height: 50px !important;
                }
                .results{
                  margin-left: 10px;
                  b{
                    font-size: $ultraLargeFontSize;
                  }
                  .indicator{
                    max-width: 95px;
                    font-size: $smallFontSize;
                    span{
                      flex-shrink: 0;
                      width: 17px;
                      height: 17px;
                      border-radius: 3px
                    }
                  }
                }
              }
            }
          }
        }
        ul.certificates{
          li{
            max-width: 500px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .bottom-nav {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #f7f8ff;
    border: 1px solid #e8e8e8;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    padding: 0 30px;
    z-index: 9;
    a {
      padding: 10px;
      margin: 0 10px;
      position: relative;
      span {
        font-size: $smallFontSize;
        color: $darkColor;
        display: flex;
        flex-direction: column;
        img {
          height: 20px;
          margin-bottom: 5px;
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 0;
        transition: 0.2s;
        background-color: #676ebb;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &.active {
        span {
          filter: invert(26%) sepia(44%) saturate(1310%) hue-rotate(202deg)
            brightness(76%) contrast(89%);
        }
        &::after {
          height: 3px;
        }
      }
    }
  }
  header {
    background-color: #f7f7f7;
    .wrapper{
      height: 50px;
      a.brand{
        img{
          width: 40px;
          height: 40px;
          margin-right: 5px;
        }
        b{
          font-size: $smallFontSize;
          max-width: 125px;
          line-height: 1.1;
        }
      }
      .user{
        >a{
          width: 30px;
          height: 30px;
        }
        >span{
          display: none;
        }
        button.logout{
          display: none;
        }
      }
    }
  }
  section {
    min-height: calc(100vh - (51px + 50px));
  }
  #login,
  #register {
    .wrapper {
      padding-bottom: 90px;
      form {
        padding: 30px 10px;
        ul.tabs {
          margin: -30px -10px;
          margin-bottom: 20px;
          width: calc(100% + 20px);
          li {
            button {
              a {
                img {
                  height: 32px;
                }
                .right {
                  b {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        p {
          font-size: $smallFontSize;
        }
        .fields {
          padding: 0;
        }
      }
    }
  }
  #student-topics,
  #student-tests {
    background-color: #fff;
    .wrapper {
      padding-top: 0;
      .top {
        position: relative;
        margin-bottom: 20px;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        border-radius: 18px;
        overflow: hidden;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-position: bottom center;
        .left {
          padding-right: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          .MuiInputBase-root {
            width: 100%;
            height: 100%;
          }
          h1,
          .progress {
            display: none;
          }
        }
        .right {
          display: none;
        }
      }
      .bottom {
        > ul {
          margin-top: 0;
        }
      }
    }
  }
  #student-topics {
    .wrapper {
      .bottom {
        > ul {
          width: 100%;
          padding: 0;
          > li {
            width: 100%;
            padding: 7.5px;
            margin-right: 0;
            margin-bottom: 10px;
            box-shadow: none !important;
            background: #f2f5ff;
            border: 1px solid #c6cee8;
            border-radius: 4px;
            i.number {
              width: 25px;
              height: 25px;
              top: 15px;
              left: 15px;
              font-size: $mediumFontSize;
            }
            .top-part {
              display: flex;
              align-items: flex-start;
              margin-bottom: 7.5px;
              width: 100%;
              img {
                margin-bottom: 0;
                margin-right: 10px;
                max-width: 130px;
              }
              span,
              p {
                text-align: left;
                font-size: $smallFontSize;
              }
              span {
                margin-bottom: 5px;
              }
              p {
                margin-bottom: 0;
              }
            }
            .hover {
              opacity: 0 !important;
              pointer-events: none !important;
              transform: translateY(-100%) !important;
              z-index: 2;
              &.force-hover{
                opacity: 1 !important;
                pointer-events: all !important;
                transform: translateY(0) !important;
              }
              span.status{
                display: none;
              }
              ul{
                align-items: center;
                flex-direction: row;
                li{
                  height: 45px;
                  margin: 0 5px;
                  a{
                    font-size: $largeFontSize;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    span{
                      display: none;
                    }
                  }
                }
              }
            }
            .footer {
              display: none;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  #student-tests {
    .wrapper {
      .top {
        padding: 0;
        .left {
          display: flex;
          align-items: center;
          padding: 10px 0;
          position: static;
          h1 {
            display: block;
            font-size: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            margin-bottom: 0;
          }
        }
      }
      .bottom {
        > ul {
          width: 100%;
          padding: 0;
          > li {
            margin-right: 0;
            background: #f2f5ff;
            border: 1px solid #c6cee8;
            border-radius: 4px;
            margin-bottom: 10px;
            a {
              display: flex;
              flex-direction: row;
              align-items: stretch;
              b {
                font-size: $largeFontSize;
              }
              .test-icon {
                height: 92px;
                width: 82px;
                margin-bottom: 0;
                margin-right: 15px;
                &::after {
                  width: 30px;
                  height: 30px;
                  right: 5px;
                }
              }
              p {
                text-align: left;
                font-size: $mediumFontSize;
              }
              ul.results {
                padding-top: 10px;
                margin-top: auto;
                justify-content: flex-start;
                li {
                  font-size: $smallFontSize;
                  line-height: 0.7;
                  margin-left: 0;
                  span {
                    width: 20px;
                    height: 20px;
                    margin-right: 3px;
                    flex-shrink: 0;
                    img {
                      width: 13px;
                    }
                  }
                }
              }
              .right-part {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  #topic {
    .main {
      .wrapper {
        ul.container {
          > li {
            h1.child{
              font-size: $largeFontSize;
            }
           .last-slide{
            >img{
              width: 100px;
            }
            h1.child{
              font-size: $largeFontSize;
            }
           }
           &.heading-image-text {
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            img{
              margin-right: 0;
              margin-bottom: 10px;
            }
           }
          }
        }
      }
    }
  }

  #test {
    .header{
      .wrapper{
        .left{
          flex: initial;
          button.back{
            width: 20px;
          }
          .heading,
          p{
            display: none;
          }
        }
        .mid{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img{
            width: 25px;
            margin-right: 2px;
            margin-top: -2px;
          }
          time{
            font-size: $largeFontSize;
            width: 60px;
          }
        }
        .right{
          justify-content: space-between;
          .answered{
            font-size: 11px;
            b{
              font-size: $mediumFontSize;
            }
          }
          button{
            width: 90px;
            height: 35px;
            font-size: $smallFontSize;
          }
        }
      }
    }
    .main {
      .wrapper {
        ul.container {
          > li {
            .question{
              padding: 10px;
              padding-top: 23px;
              ul{
                top: 7px;
                li{
                  font-size: $smallFontSize;
                }
              }
            }
            .answers {
              flex-direction: column;
              .table{
                padding-right: 0;
                padding-bottom: 10px;
                table{
                  th,
                  td{
                    width: 35px;
                    height: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #result {
    .wrapper{
      .body{
        margin: auto 0;
        padding: 30px 0;
        .holder{
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 40px;
          h2::after{
            left: -20px;
          }
        }
        ul{
          justify-content: center;
          li{
            margin-right: 30px;
            img{
              width: 33px;
              height: 33px;
              margin-right: 5px;
            }
            .info{
              b{
                font-size: $largeFontSize;
              }
            }
          }
        }
        &::after{
          display: none;
        }
      }
      .footer{
        display: flex;
        flex-wrap: wrap;
        margin-top: auto;
        margin-bottom: 0;
        button{
          margin-right: 0;
          width: 100%;
          max-width: calc(50% - 10px);
          &:first-of-type{
            margin-right: 10px;
          }
          &.certificate{
            max-width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
    &::before{
      height: 30px;
      width: 59px;
    }
    &::after{
      width: 48px;
      height: 32px;
    }
  }

  footer{
    .wrapper{
      p{
        font-size: 11px;
        max-width: 180px;
      }
    }
  }
}
